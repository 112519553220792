import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZohoRetrieveSubscriptionResponse } from 'src/app/model/subscripition-request';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public jwtToken: string = "checkLocalStorage";
  public viewTitle: string = null;
  public subscriptionData:ZohoRetrieveSubscriptionResponse;
  public displayText: string= "";
  public scope: Array<any> | boolean = false;
  constructor() { }




    
    public getScope(): Array<any> | boolean {
        return this.scope;
    }

    public setScope(scope: any): void {
        this.scope = scope;
    }

  
}
