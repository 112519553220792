import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZohosubscriptionService } from '../../services/zohosubscription.service';
import { GlobalService } from '../../services/global.service';
import { DeeplinkService } from '../../services/deeplink.service'
import { PlatformLocation } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceService } from 'src/app/pages/services/device.service';
import { Device } from 'src/app/model/device';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import * as internal from 'events';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  hostedpageId: string;
  subscriptionDetails: any;
  isPaymentRedirect: boolean = false;
  headless: boolean = false;
  pageLoader: boolean = false;
  subscriptionList: any;
  btnSubscribeloader: boolean = false;
  deviceId: string;
  isNewSubscription = false;
  deviceTwinUpdated = false;
  displaytext: string = "You have successfully subscribed to selected feature(s) on the device";
  deviceType: string;
  deviceDetails: Device;
  counter:number = 0;

  constructor(private _zohosubscriptionService: ZohosubscriptionService, private route: ActivatedRoute,
    public _global: GlobalService, public _deepLink: DeeplinkService, private location: PlatformLocation
    , private modalService: NgbModal, private _deviceService: DeviceService, private _snackBar: MatSnackBar) {

    location.onPopState((event) => {
      history.forward();
      history.pushState(null, null, location.href);
      return false;
    });

    if (this.route.snapshot.queryParams['hostedpage_id']) {
      this.isPaymentRedirect = true;
      this.hostedpageId = route.snapshot.queryParams.hostedpage_id;
    }
    if (this.route.snapshot.queryParams['headless']) {
      this.headless = route.snapshot.queryParams.headless;
    }
    if (this.route.snapshot.queryParams['deviceId']) {
      this.deviceId = route.snapshot.queryParams.deviceId;
    }
  }
  ngOnInit(): void {
    if (this._global.displayText != null) {
      this.displaytext = this._global.displayText;
    }
    if(this.deviceId != null ){
      this.getDeviceType();
      this.confirmSubscriptionAddedToDevice();
    }
    history.pushState(null, null, location.href);
  }

  getDeviceType() {
    this._deviceService.GetDeviceType(this.deviceId)
    .subscribe((res: any) => {
      this.deviceType = res.responseBody;
    });
  }

  confirmAgainSubscriptionAddedToDevice(){
    this.counter = this.counter + 1;
    var self = this;
    if(this.counter < 10){
      setTimeout(function () {
        self.confirmSubscriptionAddedToDevice();
      }, 7000);
    }else{
      this.deviceTwinUpdated = true;
    }
    
  }

  
  confirmSubscriptionAddedToDevice(){

    this._deviceService.GetDeviceByDeviceId(this.deviceId).subscribe(
      (res: MMSHttpResponseGeneric<Device>) => {
        this.deviceDetails = res.responseBody;
        if (typeof this.deviceDetails != undefined && this.deviceDetails != null && this.deviceDetails.subscriptionId != null) 
        {
          this.deviceTwinUpdated = true;
        } 
        else 
        {
          console.log("subscriptionId", this.deviceDetails.subscriptionId);
          this.confirmAgainSubscriptionAddedToDevice();
        }
      },
      (error) => {
        this._snackBar.open(error.error.errorMessage, "error!!", {
          duration: 5000,
        });
        console.log(error);
        this.deviceTwinUpdated = true;
      }
    );
    
  }

  goToApp() {
    var TevAndriodDeeplinkurl = 'http://com.tevapp/goToHome?deviceId=' + this.deviceId
    var WsdAndriodDeeplinkurl = 'http://com.tevapp/goToMainActivity?deviceId=' + this.deviceId

    var TevIosDeeplinkurl = 'com.tevapp://goToHome?deviceId=' + this.deviceId
    //var WsdIosDeeplinkurl = 'http://com.tevapp/goToMainActivity?deviceId=' + this.deviceId

    switch (this.deviceType) {
      case "TEV":
        this._deepLink.deeplink(TevAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
      case "WSD":
        this._deepLink.deeplink(WsdAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
      default:
        this._deepLink.deeplink(TevAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
    }

  }



}


