import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './services/auth.service';
import { RandomGuard } from './guard/random.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { LoginComponent } from './component/login/login.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmailResetLinkComponent } from './component/email-reset-link/email-reset-link.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { InviteConfirmComponent } from './component/invite-confirm/invite-confirm.component';
import { HighchartsChartModule } from 'highcharts-angular';



@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    EmailResetLinkComponent,
    ResetPasswordComponent,
    InviteConfirmComponent
  ],
  providers:[
    AuthGuard,
    AuthService,
    RandomGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    HighchartsChartModule
  ]
})
export class AuthModule { }
