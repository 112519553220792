import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SubscriptionRequest, ZohoRetrieveSubscriptionResponse } from 'src/app/model/subscripition-request';
import { GlobalService } from '../../services/global.service';
import { ZohosubscriptionService } from '../../services/zohosubscription.service';
import { CommonModule } from '@angular/common';
import { AddOn, Plan } from 'src/app/model/plan-request';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { environment } from 'src/environments/environment';
import { DeviceService } from '../../services/device.service';
import { parseMetadata } from '@angular/localize/src/utils';
import { MatSelectionListChange } from '@angular/material/list';
import { DeeplinkService } from '../../services/deeplink.service';
import { MatSelectionList } from '@angular/material/list';



@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlanComponent implements OnInit {

  plans: Plan[] = [];
  headless: boolean = false;
  token: string;
  btnSubscribeloader: boolean = false;
  addonList: string[] = [];
  subscriptionRequest: SubscriptionRequest = new SubscriptionRequest();
  subscriptionRes: any;
  pageLoader: boolean = false;
  deviceId: any;
  subscriptionNumber: string = null;
  isUpdate: boolean = false;
  displayGlobal: string;
  testData: any;
  amount: number = 0;
  nowDate: Date = new Date();
  subscriptionData: ZohoRetrieveSubscriptionResponse;
  isLoading = [];
  isValidItem = false;
  product: string;
  unusedCredit: number;
  getUnusedCreditIsSuccess: boolean = false;
  taxPerc: number = 18;
  subscriptionDetails: any[] = [];
  mask: any;
  noMask: any;
  selPlan: string = null;
  selectedAddOns: AddOn[] = [];
  isMask: boolean = false;
  isNoMask: boolean = false;
  selAddOnCount:number = 0;
  isNoFeatureToSelect: boolean = false;
  subheaderTextForAddOns: string = 'Select Features';
 
  
 
  constructor(private _snackBar: MatSnackBar, private _zohosubscriptionService: ZohosubscriptionService,
    private route: ActivatedRoute, private _authService: AuthService,
    public _global: GlobalService, private _router: Router, private _deviceService: DeviceService,public _deepLink: DeeplinkService) {

    if (this.route.snapshot.queryParams['accessToken']) {
      this.token = route.snapshot.queryParams.accessToken;
    }
    if (this.route.snapshot.queryParams['headless']) {
      if(route.snapshot.queryParams.headless == "true"){
        this.headless = true;
      }
    }
    if (this.route.snapshot.queryParams['deviceId']) {
      this.deviceId = route.snapshot.queryParams.deviceId;
      if (this.deviceId == null) {
        this._router.navigateByUrl('page/device-list')
      }
    }
    if (this.route.snapshot.queryParams['subscriptionNumber']) {
      this.subscriptionNumber = route.snapshot.queryParams.subscriptionNumber;
      this.isUpdate = true;
      if (this.subscriptionNumber == null || this.subscriptionNumber == "" || this.deviceId == null || this.deviceId == "") {
        this._router.navigateByUrl('page/device-list')
      }
    }

    if (this.route.snapshot.queryParams['product']) {
      this.product = route.snapshot.queryParams.product;
    } else {
      this.product = 'TEV';
    }

  }

  //private lastSevenDaysSelected = false;
  ngOnInit(): void {
    this.pageLoader = true;
    setTimeout(() => {
    this._global.viewTitle = "Plans";

    
    });
    
    if(this.product == 'WSD')
      this.subheaderTextForAddOns = "This plan comes with following features";
    //check the request is from mobile client or not , if yes then set the token  & clientId in localStorage
    if (this.headless) {
      localStorage.setItem(environment.CLIENT_ID, "tev.mobileApp");
      localStorage.setItem(environment.JWT_TOKEN, this.token);
    }
    //Display Plans
    this.displayPlan();
  }

  displayPlan() {
    if (this.isUpdate)
      this.getSubscriptionDetails(this.subscriptionNumber);
    else
      this.getPlans();
  }

  getPlans() {
    this._zohosubscriptionService.getListofPlan(this.headless, this.product, this.deviceId)
      .subscribe((res: MMSHttpResponseGeneric<Plan[]>) => {
        
        if (res.errorMessage == null) {
          console.log(res.responseBody);
          this.plans = res.responseBody;
          if (this.plans.length > 0) {
            this.plans.forEach(element => {
              element.addons.forEach(element => {
                element.isDisabled = false;
                element.selected = false;
              });
             });
            //Select the feature and disable it, if end user try's to modify the current plan
            if (this.isUpdate &&  this._global.subscriptionData.status == 'live')
            {
              this.selectexistingAddOnAndDisable(this._global.subscriptionData);
            }
            else if(this.product == 'TEV2')
            {
              if(this.plans.find(x =>  x.plan_code == environment.Plan_Code.toString())!=undefined)//new added
              this.selectedAddOns = this.plans.find(x =>  x.plan_code == environment.Plan_Code.toString()).addons.filter(x => x.isDisabled == false && x.price ==0 && x.selected == false);
            }
         }
          this.pageLoader = false;
        }
        else {
          this._snackBar.open(res.errorMessage, '', {
            duration: 5000,
          });
          this.pageLoader = false;
        }
      },
        error => {
          this.pageLoader = false;
          console.log(error);
          this._snackBar.open(error.error.errorMessage, 'error!!', {
            duration: 5000,
          });
        });
  }

  getSubscriptionDetails(subscriptionId) {
    this._zohosubscriptionService.getSubscriptionDetailsById(subscriptionId)
      .subscribe((res: MMSHttpResponseGeneric<ZohoRetrieveSubscriptionResponse>) => {
        this._global.subscriptionData = res.responseBody;
        this.getPlans();
      }, error => {
        console.log(error);
        this._snackBar.open(error.error.errorMessage, 'error!!', {
          duration: 5000,
        });
      });
  }

  selectexistingAddOnAndDisable(currentPlan) {
    if (currentPlan != null && this.plans.length > 0) {
      this.plans.forEach(function (plan) {
        if (currentPlan.plan.planCode === plan.plan_code) {
          plan.addons.forEach(function (addOnAvailable) {
            currentPlan.addOns.forEach(function (addOnSelectedInCurrentPlan) {
              if (addOnAvailable.addon_code == addOnSelectedInCurrentPlan.addOnCode) {
                addOnAvailable.isDisabled = true;
              }
            })
          });
        }
      });
  
      this.selectedAddOns = this.plans
        .find(x => x.plan_code == currentPlan.plan.planCode)
        .addons.filter(x => x.isDisabled == true);
        
      if( this.plans.find(x => x.plan_code == currentPlan.plan.planCode)
        .addons.filter(x => x.isDisabled == false).length == 0){
          this.isNoFeatureToSelect = true;
      }

      if (this.selectedAddOns.some(x => +x.addon_code.split("-")[1] == environment.Mask)) {
        this.plans[0].addons.forEach(x => {
          if (+x.addon_code.split("-")[1] == environment.NoMask) {
            x.isDisabled = true;
          }
        });
      }
      if (this.selectedAddOns.some(x => +x.addon_code.split("-")[1] == environment.NoMask)) {
        this.plans[0].addons.forEach(x => {
          if (+x.addon_code.split("-")[1] == environment.Mask) {
            x.isDisabled = true;
          }
        });
      }

      this.selAddOnCount = this.selectedAddOns.length;
    }
  }
 
  
  
  //-------------Mask - No Mask Selection logic----------------------------//
  onSelectionChange($event: MatSelectionListChange, i:number) {
  
    const plan = this.plans[i];
   //console.log("plan", plan)
 
  const selectedItem = plan.addons.find(addon => addon.addon_code === $event.option.value.addon_code);
  //console.log("selectedItem", selectedItem)
  selectedItem.selected = $event.option.selected;

    const lastSevenDaysAddon = plan.addons.find(addon => addon.addon_code === 'TEV2-002');
    const otherSelectedAddons = plan.addons.filter(addon => addon.addon_code !== 'TEV2-002');
    const loiter = this.selectedAddOns.find(addon => addon.addon_code === 'TEV2-03');
    const crowd = plan.addons.find(addon => addon.addon_code === 'TEV2-01');
    const trespassing = plan.addons.find(addon => addon.addon_code === 'TEV2-007');

  if (lastSevenDaysAddon) {
    lastSevenDaysAddon.selected = true;
    //console.log("lastSevenDaysAddon", lastSevenDaysAddon)
    const isAllUnChecked = plan.addons.filter(x => x.selected === true && x.addon_code != 'TEV2-002' )
   // console.log("isAllUnChecked", isAllUnChecked)
      if(isAllUnChecked && isAllUnChecked.length == 0){
        lastSevenDaysAddon.selected = false;
      }
  }

 
    if (this.selectedAddOns.length > 0) {
      if ($event.option.value.addon_code.split("-")[1] == environment.Mask) {
        console.log("mask", environment.Mask)
        var index: number = this.selectedAddOns.indexOf(this.selectedAddOns.find(x => +x.addon_code.split("-")[1] == environment.NoMask));
        if (index !== -1) {
          this.selectedAddOns = this.selectedAddOns.filter(x => +x.addon_code.split("-")[1] != environment.NoMask)
        }
      }
      if ($event.option.value.addon_code.split("-")[1] == environment.NoMask) {
        var index: number = this.selectedAddOns.indexOf(this.selectedAddOns.find(x => +x.addon_code.split("-")[1] == environment.Mask));
        if (index !== -1) {
          this.selectedAddOns = this.selectedAddOns.filter(x => +x.addon_code.split("-")[1] != environment.Mask)
        }
      }
        }
    }


  
    
      
    
  pushAddOnToList(): boolean {
    if (this.selectedAddOns.length > 0) {
      this.addonList = [];
      this.amount = 0;
      this.selectedAddOns.forEach(element => {
        this.addonList.push(element.addon_code);
        this.amount = this.amount + element.price;
        // adding 18% GST Tax.
        this.amount = this.amount + element.price / 100 * this.taxPerc;
      });
    } else {
      return false;
    }

    console.log("addonList", this.addonList);
    return true;
  }

  onSubscribe(index: number) {
    if(this.product == "WSD"){
      this.selectedAddOns = this.plans[index].addons;
    }
    if (this.pushAddOnToList()) {
      if(this.isUpdate && this.selectedAddOns.length == this.selAddOnCount){
        this._snackBar.open("select minimum one feature to proceed", "error!!",{  duration: 5000});
      }
      else{
        this._global.displayText = null;
        this.isLoading[index] = true;

        if (!this.isUpdate)
          this.checkUnusedCreditBeforePurchase(index);
        else {
          this.checkPaymentNeedToCollectOrNot(index);
        }
      }
    } else {
      this._snackBar.open("select minimum one feature to proceed", "error!!",{  duration: 5000});
    }

  }

  createSubscriptionOnline(index: number) {
    this._zohosubscriptionService.createNewSubscriptionOnline({
      "planCode": this.plans[index].plan_code, "deviceId": this.deviceId,
      "addons": this.addonList, "headless": this.headless
    }).subscribe((res: MMSHttpResponseGeneric<string>) => {
      this.btnSubscribeloader = false;
      if (this.headless) //for mobile
        this._router.navigateByUrl('/tev/hosted-page?hostedpageUri=' + res.responseBody);
      else
        window.location.href = res.responseBody;
    }, error => {
      this._snackBar.open(error.error.errorMessage, 'error!!', {
        duration: 5000,
      });
      console.log(error);
    });
  }

  createSubscriptionOffline(index: number) {

    this._zohosubscriptionService.createNewSubscriptionOffline({
      "planCode": this.plans[index].plan_code, "deviceId": this.deviceId,
      "addons": this.addonList, "headless": this.headless,"secretkey":environment.OfflineSubscriptionSecretkey
    }).subscribe((res: MMSHttpResponseGeneric<string>) => {
      this.btnSubscribeloader = false;
      if (res.errorMessage == null) {
        this._global.displayText = res.successMessage;
        this._router.navigateByUrl(res.responseBody);
        //this.isLoading[index] = false;
        //window.location.href = res.responseBody;
      }
      else {
        this.isLoading[index] = false;
        this._snackBar.open("Error occured try again after sometime.", '', {
          duration: 5000,
        });
      }
    }, error => {
      this.isLoading[index] = false;
      this.btnSubscribeloader = false;
      this._snackBar.open(error.error.errorMessage, '', {
        duration: 5000,
      });
      console.log(error);
    });
  }

  updateSubscriptionOnline(index: number) {
    this._zohosubscriptionService.updateSubscriptionOnline({
      "subscriptionId": this.subscriptionNumber, "planCode": this.plans[index].plan_code, "deviceId": this.deviceId,
      "addons": this.addonList, "headless": this.headless
    })
      .subscribe((res: any) => {

        this.btnSubscribeloader = false;
        if (this.headless)
          this._router.navigateByUrl('/tev/hosted-page?hostedpageUri=' + res.responseBody);
        else
          window.location.href = res.responseBody;

      }, err => {
        this._snackBar.open(err.error.errorMessage, '', {
          duration: 2000,
        });
      });
  }

  updateSubscriptionOffline(index: number) {
    this._zohosubscriptionService.updateSubscriptionOffline({
      "subscriptionId": this.subscriptionNumber, "planCode": this.plans[index].plan_code, "deviceId": this.deviceId,
      "addons": this.addonList, "headless": this.headless
    })
      .subscribe((res: any) => {

        this.btnSubscribeloader = false;
        if (res.successMessage == "success") {

          this._router.navigateByUrl(res.responseBody);
          //window.location.href = res.responseBody;
        }
        else {
          this._snackBar.open("Try again!", '', {
            duration: 5000,
          });
        }

      }, err => {
        this._snackBar.open(err.error.errorMessage, '', {
          duration: 5000,
        });
      });
  }
  checkPaymentNeedToCollectOrNot(index) {

    this._zohosubscriptionService.computeCost({
      "subscriptionId": this.subscriptionNumber, "planCode": this.plans[index].plan_code,
      "addons": this.addonList
    })
      .subscribe((res: MMSHttpResponseGeneric<any>) => {
        if (res.successMessage == "success") {
          if (+res.responseBody >= 1)
            this.updateSubscriptionOnline(index);
          else
            this.updateSubscriptionOffline(index);
        }
        else {
          this._snackBar.open("try again!", 'error!!', {
            duration: 5000,
          });
        }

      }, err => {
        this._snackBar.open(err.error.errorMessage, 'error!!', {
          duration: 5000,
        });
      });

  }

  checkUnusedCreditBeforePurchase(index) {
    console.log("checkUnusedCreditBeforePurchase headless value = " + this.headless);
    this._zohosubscriptionService.getUnusedCredits()
      .subscribe((res: MMSHttpResponseGeneric<any>) => {
        if (res.successMessage == "success") {
          this.unusedCredit = res.responseBody;
          var itemCount = this.addonList.length + 1;
          //adding plan amount
          var amt = this.amount + this.plans[index].recurring_price;
          //adding 18 % tax for the plan selected.
          amt = amt + this.plans[index].recurring_price / 100 * this.taxPerc;

          if (+amt > +this.unusedCredit)
            this.createSubscriptionOnline(index);
          else
            this.createSubscriptionOffline(index);
        }
        else {
          this.btnSubscribeloader = false;
          this.isLoading[index] = false;
          this._snackBar.open("Try again!", '', {
            duration: 5000,
          });
        }
      }, err => {
        this.isLoading[index] = false;
        this._snackBar.open(err.error.errorMessage, '', {
          duration: 5000,
        });
      });
  }

  goToApp() {
    var TevAndriodDeeplinkurl = 'http://com.tevapp/goToHome?deviceId=' + this.deviceId;
    var WsdAndriodDeeplinkurl = 'http://com.tevapp/goToMainActivity?deviceId=' + this.deviceId;

    var TevIosDeeplinkurl = 'com.tevapp://goToHome?deviceId=' + this.deviceId;
    //var WsdIosDeeplinkurl = 'http://com.tevapp/goToMainActivity?deviceId=' + this.deviceId

    switch (this.product) {
      case "TEV":
        this._deepLink.deeplink(TevAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
      case "WSD":
        this._deepLink.deeplink(WsdAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
      default:
        this._deepLink.deeplink(TevAndriodDeeplinkurl, TevIosDeeplinkurl)
        break;
    }
  }


}
