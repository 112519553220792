<div class="grid-container">
    <div *ngIf="deviceList.length==0 && !pageLoader" style="text-align: center;">
    <div style="font-size: 48px;
    opacity: 0.6;"><i class="fas fa-video-slash"></i></div>
    <div style="font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    opacity: 0.7;">No Device</div>
</div>
    <div class="row" >
        <div class="col-lg-12" *ngIf="deviceList.length>5">
        <input style="margin-bottom: 15px; "type="text" class="form-control" id="search-text" aria-describedby="search-text" 
        [(ngModel)]="searchText" placeholder="Search by device name or location" 
        autofocus>
    </div>
            <mat-spinner style="width: 100px;
    height: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);" color="warn" *ngIf="pageLoader"></mat-spinner>
        <div @fadeIn class="col-xl-4 col-lg-6 col-md-6" *ngFor="let item of deviceList | appFilter: searchText; let i = index">
            <mat-card class="shadow" style="height:325px;">
                <div [hidden]="!item.videoShow" style="width:100%;height:100%;;position:absolute;left:0;top:0;border-radius: 6px;z-index:9999">
                    <i (click)="closePlayer(item)" class="fas fa-times" style="position: absolute;top:2px;right:3px;color:white;font-size: 12px;
                    z-index: 99;
                    cursor: pointer;
                    background-color: black;
                    color: white;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    padding: 5px 6px;"></i>
                    <video-js id="{{item.id}}" class="player video-js vjs-default-skin vjs-fill" autoplay preload="none"></video-js>
                </div>
                <div style="text-align: center;">
                    <button style="position: absolute;left:8px;" *ngIf="item.deviceType=='TEV' || item.deviceType=='TEV2' " [disabled]="isLoading[item.id]" mat-flat-button color="primary" (click)="GetPicture(item);" > 
                        Test
                        <mat-icon *ngIf="isLoading[item.id]">
                            <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;" diameter="18">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                    <div style="font-size: 16px;text-transform: capitalize;">
                        <span matTooltip="Device is online" style="font-size: 14px;cursor:pointer;position: absolute;top:10px;right:10px;" *ngIf="item.connected == true"><i class="fas fa-circle" style="color:limegreen"></i></span>
                        <span matTooltip="Device is offline" style="font-size: 14px;cursor: pointer;position: absolute;top:10px;right:10px;" *ngIf="item.connected == false"><i class="fas fa-circle" style="color:lightgrey"></i></span>
                        {{item.name}}</div>
                    <div >
                        <div *ngIf="(item.deviceType=='TEV' || item.deviceType=='TEV2') && item.disabled == false" style="position: absolute;
                        right: 40px;
                        top: 10px;
                        color:#dc202e">
                            <i style='cursor: pointer;' *ngIf="!item.videoLoading" class="fa fa-play" aria-hidden="true" matTooltip="See Live Footage" (click)="playVideo(item)"></i>
                            <mat-spinner *ngIf="item.videoLoading" color="warn" diameter="18">
                        </mat-spinner>
                        </div>
                        <div matTooltip="People Count report" *ngIf="(item.deviceType=='TEV' || item.deviceType=='TEV2') && item.disabled == false"  style="position: absolute;
                        right: 70px;
                        top: 10px;
                        color:darkviolet;cursor: pointer;">
                            
                            <i style="font-size: 17px;" (click)="OpenPeopleCount(item.id)" class="fas fa-street-view"></i>
                        </div>
                    <span matTooltip="Device located at {{item.locationName}}" style="margin-top: 5px;font-size: 12px;font-weight: 300;text-transform: capitalize;"><i style="opacity: 0.7;" class="fas fa-map-marker-alt"></i> {{item.locationName}}</span>
                   <div matTooltip="Open test page" *ngIf="item.deviceType=='WSD'"  style="position: absolute;
                   right: 40px;
                   top: 10px;
                   color:#dc202e;cursor: pointer;">
                       <i (click)="OpenWSDtest(item.id,item.name)" class="fas fa-vial"></i>
                   </div>
                   <div matTooltip="See all telemetry data" *ngIf="item.deviceType=='WSD'"  style="position: absolute;
                   right: 70px;
                   top: 10px;
                   color:#dc202e;cursor: pointer;">
                       <i (click)="OpenWSDSummary(item.id,item.name)" class="fas fa-table"></i>
                   </div>
                </div>
                
                </div>
                <hr>
               
                <div style="font-size: 12px;">
                    <div>
                        <span style="width: 125px;display: inline-block;"><i class="fas fa-cloud-download-alt" style="opacity: 0.7;margin-right: 5px;"></i>Firmware Version:</span><span style="margin-left: 5px;font-weight: 500;">{{item.firmwareVersion}}</span>
                    </div>
                    <div>
                        <span style="width: 125px;display: inline-block;"><i class="fas fa-wifi" style="opacity: 0.7;margin-right: 5px;"></i>Wifi Name:</span><span style="margin-left: 5px;font-weight: 500;">{{item.wifiName}}</span>
                    </div>
                    <div>
                        <span style="width: 125px;display: inline-block;margin-right: 5px;;"><i class="fas fa-eye" style="opacity: 0.7;margin-right: 5px;"></i> Available Features:</span>

                            <span style="font-weight: 500;" *ngIf="item.availableFeatures==null">None</span>
                            <div style="display: block;
                            width: 140px;
                            margin-left: 132px;
                            margin-top: -20px;">
                                <span style="font-weight: 500;" *ngFor="let feature of item.availableFeatures">{{feature}}, </span> 
                            </div>
                            
                    </div>
                </div>
               
               
                <div class="subs-button-holder">
                    
                    <div *ngIf="item.disabled == true">
                        <mat-spinner color="warn" diameter="16" style="width: 18px;
                        height: 18px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;">
                        </mat-spinner>
                        <span style="font-weight: 300;opacity: 0.9;font-size: 12px;margin-right:5px;" >Device is being deleted</span>
                        <i #tooltip="matTooltip"
                        matTooltip="To Complete the device deletion please make sure the device is online."
                        [matTooltipPosition]="'above'"
                        matTooltipHideDelay="10000"
                        aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                        class="example-button"
                         style="color: gray;opacity: 0.7;margin-right: 5px;"  class="fas fa-info-circle"></i> 
                        
                    </div>
                    <div *ngIf="item.subscriptionId === null && item.disabled == false"><i style="color: red;opacity: 0.7;margin-right: 5px;" class="fas fa-exclamation-circle"></i><span style="font-weight: 300;opacity: 0.9;font-size: 12px;">No active feature subscription</span></div>
                    <div *ngIf="item.subscriptionStatus === 'cancelled' && item.disabled == false"><i style="color: red;opacity: 0.7;margin-right: 5px;" class="fas fa-exclamation-circle"></i><span style="font-weight: 300;opacity: 0.9;font-size: 12px;">Device subscription cancelled</span></div>
                    <div *ngIf="item.subscriptionStatus === 'expired' && item.disabled == false"><i style="color: red;opacity: 0.7;margin-right: 5px;" class="fas fa-exclamation-circle"></i><span style="font-weight: 300;opacity: 0.9;font-size: 12px;">Device subscription expired</span></div>
                    <div *ngIf="item.subscriptionStatus === 'live' && item.disabled == false"><i style="color:limegreen;opacity: 0.7;margin-right: 5px;" class="fas fa-check-circle"></i><span style="font-weight: 300;opacity: 0.9;font-size: 12px;">Subscription valid till {{item.subscriptionExpiryDate.toDateString()}}</span></div>
                   
                    <button mat-flat-button class="purchase-button" (click)="purchasePlan(item);" *ngIf="(item.subscriptionId === null && item.disabled == false)" > 
                    Buy Subscription
                    <mat-icon *ngIf="isLoading[item.id]">
                        <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;" diameter="18">
                        </mat-spinner>
                    </mat-icon>
                </button>
                <button mat-flat-button class="purchase-button wide"  (click)="cancelSubscription(item);"  *ngIf="item.subscriptionStatus === 'live' && item.disabled == false"  > 
                    Cancel Subscription
                    <mat-icon *ngIf="isLoading[item.id]">
                        <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;" diameter="18">
                        </mat-spinner>
                    </mat-icon>
                </button>
                <button mat-flat-button class="purchase-button wide"  (click)="modifySubscription(item);"  *ngIf="item.subscriptionStatus === 'live' && item.disabled == false"  > 
                    Modify Subscription
                    <mat-icon *ngIf="isLoading[item.subscriptionId]">
                        <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;" diameter="18">
                        </mat-spinner>
                    </mat-icon>
                </button>
                <button mat-flat-button class="purchase-button wide"  (click)="reactivateSubscription(item);" *ngIf="item.subscriptionStatus === 'non_renewing' && item.disabled == false" > 
                    Reactivate Subscription
                    <mat-icon *ngIf="isLoading[item.id]">
                        <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;"  diameter="18">
                        </mat-spinner>
                    </mat-icon>
                </button>
                <button mat-flat-button  class="purchase-button wide" (click)="updateSubscription(item);" *ngIf="item.subscriptionStatus === 'cancelled' && item.disabled == false">
                    Renew Subscription
                    <mat-icon *ngIf="isLoading[item.id]">
                        <mat-spinner class="custom" style="margin-left:3px;margin-top:2px;"  diameter="18">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </div>
            </mat-card>
            <br>
        </div>
        
    </div>
</div>
