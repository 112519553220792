import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AuthToken } from 'src/app/model/authtoken';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { environment } from 'src/environments/environment';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';
  isTrue = false;
  matcher = new MyErrorStateMatcher();
  constructor(fb: FormBuilder,
    private _router: Router,
    private _authService: AuthService, private _snackBar:MatSnackBar) {

    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }


  onSubmit() {
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this._authService.login({"clientId":environment.clientId,"email":this.loginForm.value.email,"password":this.loginForm.value.password})
    .subscribe(
      (res: MMSHttpResponseGeneric<AuthToken>) => {
      if (res.errorMessage == null) {
        this._authService.doLoginUser(environment.clientId, this.loginForm.value.email, res)
        this._router.navigateByUrl('/page/device-list');
      }
      console.log("res",res);
      this.loading = false;
    }, error=>{
      console.log("error",error)
      this.loading = false;
      this._snackBar.open(error.error.errorMessage, '', {
        duration: 2000
      });
     
    });

  }


}


