<mat-card style="margin-bottom: 15px;height:101px;background-color: #eee;width:98%" class="shadow-none">
    <div>
      <div style="margin-bottom: 10px;
      margin-left: -3px;font-weight: 300;"><i style="color: #777;" class="fas fa-exclamation-circle"></i> {{alert.alertType}}</div>
      <div style="text-transform: capitalize;font-size: 12px;"> <i style="color:#777;margin-right: 3px;" class="fas fa-map-marker-alt"></i> Device: {{alert.deviceName}} located at: {{alert.locationName}}</div>
      <div style="text-transform: uppercase;font-size: 12px;"><i style="color:#777" class="fas fa-clock"></i> {{occurDate}}</div>
    <img class="alert-image" [src]="alert.imageUrl" alt="device" />
  </div>
  </mat-card>

