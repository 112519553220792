<img class="logo" src="../../../../assets/logo/IMPACT-by-honeywell.png">
<div class="centered">
  <mat-card class="shadow" style="height:650px">
    
    <div class="tev-title">Register</div>
   
    
    <div class="example-container">
      <mat-horizontal-stepper linear #stepper>

        <!-- <mat-step [stepControl]="account">
          <form [formGroup]="account" (ngSubmit)="form1()">
            <ng-template matStepLabel>Who you are ?</ng-template>
            <label>Select: </label>
            <mat-radio-group formControlName="registerAs" (change)="onChange();" required>
              <mat-radio-button value="Org">Organisation</mat-radio-button>
              <mat-radio-button value="Individual">Individual</mat-radio-button>
            </mat-radio-group>
            <div>
              <button mat-button matStepperNext type="submit">Next</button>
            </div>
          </form>
        </mat-step> -->

        <mat-step [stepControl]="org">
          <form [formGroup]="org" (ngSubmit)="form2()">
            <ng-template matStepLabel>Organization details</ng-template>
            <mat-form-field>
              <mat-label>Organization Name</mat-label>
              <input matInput formControlName="orgName">
              <mat-spinner *ngIf="org.controls.orgName.status=='PENDING'" matSuffix diameter="18" color="warn"></mat-spinner>
              <mat-error *ngIf="org.controls['orgName'].invalid && !org.controls.orgName.hasError('required')">
                Please enter a valid name  
              </mat-error>
              <mat-error *ngIf="org.controls.orgName.hasError('orgName') && !org.controls['orgName'].invalid && !org.controls.orgName.hasError('required')">
                Organization name already exist
              </mat-error>
              <mat-error *ngIf="org.controls.orgName.hasError('required')">
                Organization name is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Description</mat-label>
              <input matInput formControlName="orgDescription" maxlength="200">
            </mat-form-field>
            <mat-form-field>
              <mat-label>GSTIN</mat-label>
              <input matInput formControlName="gstin">
              <mat-error *ngIf="org.controls.gstin.hasError('gstin') && !org.controls.gstin.hasError('required')">
                GSTIN not in correct format. Correct format e.g 37AAACB1534F2Z4
              </mat-error>
              <mat-error *ngIf="org.controls.gstin.hasError('required')">
                GSTIN is required
              </mat-error>
            </mat-form-field>
            <div>
              <br>
              <button mat-flat-button class="login-button" matStepperNext type="submit">Next</button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="user">
          <form [formGroup]="user" (ngSubmit)="form3()">
            <ng-template matStepLabel>Org admin details</ng-template>
            <mat-form-field>
              <mat-label>Firstname</mat-label>
              <input matInput formControlName="firstName" required>
              <mat-error *ngIf="user.controls.firstName.hasError('required')">
                Firstname is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Lastname</mat-label>
              <input matInput formControlName="lastName" required>
              <mat-error *ngIf="user.controls.lastName.hasError('required')">
                Lastname is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" required>
              <mat-error *ngIf="user.controls.email.hasError('required')">
                Email is required
              </mat-error>
  
              <mat-error *ngIf="user.controls.email.hasError('email') && !user.controls.email.hasError('required')">
                Invalid email address
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mobile Number</mat-label>
              <input matInput formControlName="mobileNumber" required maxlength="10">
              <span style="opacity:0.8" matPrefix>+91 &nbsp;</span>
              <mat-error *ngIf="user.controls.mobileNumber.hasError('required')">
                Mobile number is required
              </mat-error>
              <mat-error *ngIf="user.controls.mobileNumber.hasError('mobileNumber') && !user.controls.mobileNumber.hasError('required')">
                Invalid Mobile number
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput formControlName="password"
                [type]="pwdHide ? 'password' : 'text'">
                <mat-hint align="start">1 Upper case,</mat-hint>
                <mat-hint align="start">1 Numeric, 1 Special Character,</mat-hint>
                <mat-hint align="start">Minimum length 8</mat-hint>
              <button type="button" mat-icon-button matSuffix (click)="pwdHide = !pwdHide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="pwdHide">
                <mat-icon>{{pwdHide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="user.controls.password.hasError('required')">
                Password is required
              </mat-error>
              <mat-error *ngIf="user.controls.password.hasError('password') && !user.controls.password.hasError('required')">
                Password policy not met
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Confirm Password</mat-label>
              <input matInput placeholder="enter the password again" formControlName="confirmPassword"
                [type]="cpwdHide ? 'password' : 'text'">
              <button type="button" mat-icon-button matSuffix (click)="cpwdHide = !cpwdHide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="cpwdHide">
                <mat-icon>{{cpwdHide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="user.controls.confirmPassword.hasError('required')">
                Confirm Password is required
              </mat-error>
              <mat-error *ngIf="user.controls.confirmPassword.hasError('confirmPassword') && !user.controls.confirmPassword.hasError('required')">
                Confirm Password does not match password
              </mat-error>
            </mat-form-field>
            <div>
              <br>
              <button style="margin-right:20px;" type="button" mat-flat-button color="primary" mat-button matStepperPrevious>Back</button>
              
              <button type="submit" mat-flat-button [disabled]="saveInProgress" class="login-button">Register
                <mat-icon *ngIf="saveInProgress">
                  <mat-spinner color="primary" diameter="20">
                  </mat-spinner>
                </mat-icon>
              </button>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

  </mat-card>
</div>