import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr"; 
import { environment } from 'src/environments/environment';
import { IHttpConnectionOptions } from '@aspnet/signalr';
import { Observable, Subject } from 'rxjs';
import { LiveStreamSignalRMessage } from 'src/app/model/live-stream-message';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2'
@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(private snackbar:MatSnackBar) { }

  private hubConnection: signalR.HubConnection
  private options:IHttpConnectionOptions={accessTokenFactory:this.getToken};
  public signalRMessages= new Subject<LiveStreamSignalRMessage>();
  public startConnection = (url:string,deviceId:String) => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                        .configureLogging(signalR.LogLevel.Information)
                        .withUrl(url, this.options)
                        .build();

    this.hubConnection
      .start()
      .then(() => {console.log('Connection started')
      this.addLiveStreamDataListener(deviceId);
    })
      .catch(err =>
        {
          Swal.fire({
            title:"Livestream Error",
            text:"Connection could not be established with video streaming service, refresh the browser and try again",
            icon:"error",
            showCloseButton:true,
          });
        });
       
  }

  private addLiveStreamDataListener = (id) => {
    this.hubConnection.on(id, (data) => {
     this.signalRMessages.next(data);
    });
  }
  public disconnect() {
    this.hubConnection.stop();
  }
  
  private getToken(): string {
  
    return localStorage.getItem(environment.JWT_TOKEN);
  }
}
