import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InviteUser } from 'src/app/model/InviteUser';
import { MMSHttpResponse, MMSHttpResponseGeneric } from 'src/app/model/response';
import { VideoStreamingResponse } from 'src/app/model/VideoStreamingResponse';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class LiveStreamService {
    constructor(private http: HttpClient) { }
    GetLiveStreamUrl(logicalDeviceId: string): Observable<MMSHttpResponseGeneric<VideoStreamingResponse>> {
        return this.http.get<MMSHttpResponseGeneric<VideoStreamingResponse>>(environment.Tev_BaseUri + '/Video/start/' + logicalDeviceId)
            .pipe(
                catchError(this.handleError)
            );

    }

    StopLiveStream(logicalDeviceId: string, stopSequenceNumber:string):Observable<MMSHttpResponse>{
        return this.http.get<MMSHttpResponse>(`${environment.Tev_BaseUri}/Video/stop/${logicalDeviceId}/${stopSequenceNumber}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

}