import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WSDTestData } from 'src/app/model/WSDTestData';
import { GlobalService } from '../../services/global.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { WSDTestService } from '../../services/wsd-test.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { pathToFileURL } from 'url';

@Component({
    selector:'wsd-test',
    templateUrl:'./wsd-test.html',
    styleUrls:['./wsd-test.scss']
})
export class WSDTest implements OnInit{
    public deviceId:string;
    public deviceName:string;
    public testData:WSDTestData={};
    public tableData:WSDTestData[];
    
    constructor(private route: ActivatedRoute,private global:GlobalService,private testService:WSDTestService,private _snackBar:MatSnackBar){
        this.route.queryParams.subscribe(x=>{
            this.deviceId=x["deviceId"];
            this.deviceName=x["deviceName"];
        });
        global.viewTitle="WSD Test"
        this.testData.GTemperatureSensorOffset2=0;
        this.testData={
            GTemperatureSensorOffset2:0,
            GTemperatureSensorOffset:0,
            ClearAir:60,
            IREDCalibration:100,
            PhotoOffset:53,
            DriftLimit:100,
            DriftBypass:0,
            TransmitResolution:1,
            TransmitThreshold:3,
            SmokeThreshold:2000

        }
        

    }
    ngOnInit(): void {
    console.log(this.deviceId);
    console.log(this.deviceName);
    this.testService.GetTestData(this.deviceId).subscribe(x=>{
     this.tableData=x.responseBody;   
     console.log(this.tableData)
    })
    }

    onSubmit(){
        console.log(this.testData)
        this.testService.SendDataToDevice(this.testData,this.deviceId).subscribe(result=>{
            this._snackBar.open("Data sent to device succesfully","",{
                duration:2000
            })
        },err=>{
            this._snackBar.open("Error Occured !","",{
                duration:2000
            })
        })

    }
    clearForm(){
        this.testData.ClearAir=null;
        this.testData.DriftBypass=null;
        this.testData.DriftLimit=null;
        this.testData.GTemperatureSensorOffset2=null;
        this.testData.GTemperatureSensorOffset=null;
        this.testData.IREDCalibration=null;
        this.testData.PhotoOffset=null;
        this.testData.SmokeValue=null;
        this.testData.TransmitResolution=null;
        this.testData.TransmitThreshold=null;
        this.testData.SmokeThreshold=null;
    }

    refreshData(){
        this.testService.GetTestData(this.deviceId).subscribe(x=>{
            this.tableData=x.responseBody;   
            console.log(this.tableData)
            this._snackBar.open("Refreshed","",{
                duration:2000
            })
           },err=>{
            this._snackBar.open("Error Occured!","",{
                duration:2000
            })
           })
    }

}