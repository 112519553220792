import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRoutingModule } from './page-routing.module';
import { MaterialModule } from '../material.module';
import { InviteUserComponent } from './component/invite-user/invite-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { PlanComponent } from './component/plan/plan.component';
import { SubscriptionComponent } from './component/subscription/subscription.component';
import { HomeComponent } from './component/home/home.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { UserProfileComponent } from './component/user/user-profile/user-profile.component';
import { DevicelistComponent } from './component/device/devicelist/devicelist.component';
import { DeviceDetailsComponent } from './component/device/device-details/device-details.component';
import { DeviceAlertFeedComponent } from './component/device-alert-feed/device-alert-feed.component';
import { DeviceAlertCardComponent } from './component/device-alert-card/device-alert-card.component';
import { HostedPageComponent } from './component/hosted-page/hosted-page.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { SingularPipe } from '../pipes/singular.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatDialogModule } from '@angular/material/dialog';
import { WSDTest } from './component/WSDTest/wsd-test';
import { WSDSummary } from './component/wsd-summary/wsd-summary';
import { HighchartsChartModule } from 'highcharts-angular';
import { PeopleCountComponent } from './component/reports/people-count';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SetupDeviceComponent } from './component/device/setup-device/setup-device.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DeviceImageComponent } from './component/device/device-image/device-image.component';
import { MatListModule } from '@angular/material/list';


@NgModule({
  declarations: [InviteUserComponent,
    ChangePasswordComponent,
    PlanComponent,
    SubscriptionComponent,
    HomeComponent,
    UserProfileComponent,
    DevicelistComponent,
    DeviceDetailsComponent,
    DeviceAlertFeedComponent,
    DeviceAlertCardComponent,
    HostedPageComponent,
    FilterPipe,
    SingularPipe,
    WSDTest,
    WSDSummary,
    PeopleCountComponent,
    SetupDeviceComponent,
    DeviceImageComponent],
  imports: [
    CommonModule,
    FormsModule,
    PageRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    CommonModule,
    NgApexchartsModule,
    MatDialogModule,
    HighchartsChartModule,
    MatButtonToggleModule,
    MatInputModule,
    MatFormFieldModule,
    QRCodeModule,
    MatListModule

  ],
  exports: [
    MatInputModule,
    MatFormFieldModule
  ]
})
export class PageModule { }
