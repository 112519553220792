<div style="text-align: center;">

 
  <div style="width:100%">
    <qrcode [hidden]="!qrGenerated" [qrdata]="QrCode" [errorCorrectionLevel]="'L'" allowEmptyString="true" [elementType]="'svg'" [margin]="2"></qrcode>
  </div>

<div [hidden]="!qrGenerated">
<div style="font-size: 30px;
font-weight: bold;
opacity: 0.5;
line-height: 30px;
margin-top: 70px;
text-align: center;">
  {{remaingTime}}
</div>

<div style="text-align: center;
color: red;
font-weight: 300;">seconds before QR expires</div>
<div style="margin-top:10px;
font-weight: 300;">
<button (click)="ScanningDone()" class="login-button" mat-flat-button>Scanning Done

</button>
</div>
</div>
<form class="device-setup-form" [hidden]="qrGenerated">
    <mat-form-field class="example-full-width">
      <mat-label>Device Name</mat-label>
      <input [(ngModel)]="deviceName" required matInput #message maxlength="256" placeholder="" name="deviceName">
    
    </mat-form-field>
    
    
    <mat-form-field class="example-full-width">
        <mat-label>Wifi Name</mat-label>
        <input [(ngModel)]="wifiName" matInput #wifiName1 maxlength="256" placeholder="" name="wifiName">
      </mat-form-field>
      
      <mat-form-field class="example-full-width">
        <mat-label>Wifi Password</mat-label>
        <input [(ngModel)]="wifiPassword" matInput #wifiPswd1 maxlength="256" placeholder="" name="wifiPswd">
       
      </mat-form-field>
      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-select [(ngModel)]="selectedLocation" name="location">
          <mat-option *ngFor="let p of locations" [value]="p">{{p.name}}</mat-option>
        </mat-select>
      </mat-form-field>
     
      <button [disabled]="qrGenerationInProgress" (click)="GenerateQR()" class="login-button" mat-flat-button type="submit" >Generate QR
        <mat-icon *ngIf="qrGenerationInProgress">
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
        </mat-icon>
      </button>
  </form>
</div>