import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RandomGuard } from '../auth/guard/random.guard';
import { NavComponent } from '../nav/nav.component';
import { HomeComponent } from '../pages/component/home/home.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { DeviceDetailsComponent } from './component/device/device-details/device-details.component';
import { DevicelistComponent } from './component/device/devicelist/devicelist.component';
import { SetupDeviceComponent } from './component/device/setup-device/setup-device.component';
import { HostedPageComponent } from './component/hosted-page/hosted-page.component';
import { InviteUserComponent } from './component/invite-user/invite-user.component';
import { PlanComponent } from './component/plan/plan.component';
import { PeopleCountComponent } from './component/reports/people-count';
import { SubscriptionComponent } from './component/subscription/subscription.component';
import { UserProfileComponent } from './component/user/user-profile/user-profile.component';
import { WSDSummary } from './component/wsd-summary/wsd-summary';
import { WSDTest } from './component/WSDTest/wsd-test';

const routes: Routes = [
  {
    path: '',
    component: NavComponent,
    children: [
      { 
        path: '', 
        component: HomeComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path: 'page/alerts',
    component: NavComponent,
    children: [
      { 
        path: '', 
        component: HomeComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/change-password',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: ChangePasswordComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/plan',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: PlanComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'tev/plan',
    component: PlanComponent,
  },
  {
    path:'page/subscription',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: SubscriptionComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'tev/subscription',
    component: SubscriptionComponent
  },
  {
    path:'tev/hosted-page',
    component: HostedPageComponent
  },
  {
    path:'page/invite-user',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: InviteUserComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/user-profile',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: UserProfileComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/device-list',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: DevicelistComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/device-details',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: DeviceDetailsComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/wsd-test',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: WSDTest,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/wsd-summary',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: WSDSummary,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/device-setup',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: SetupDeviceComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'tev/people-count',
    component:PeopleCountComponent,
  }
  //page/device-list
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }
