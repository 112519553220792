import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InviteUser } from 'src/app/model/InviteUser';
import { MMSHttpResponse, MMSHttpResponseGeneric } from 'src/app/model/response';
import { VideoStreamingResponse } from 'src/app/model/VideoStreamingResponse';
import { WSDTestData } from 'src/app/model/WSDTestData';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class WSDTestService {
    constructor(private http: HttpClient) { }
    SendDataToDevice(data: WSDTestData,deviceId:string): Observable<MMSHttpResponse> {
        return this.http.post<MMSHttpResponse>(environment.Tev_BaseUri + `/WSDtest/send/${deviceId}`,data)
            .pipe(
                catchError(this.handleError)
            );

    }

    GetSummaryData(deviceId:string):Observable<MMSHttpResponseGeneric<any[]>>{
         return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+`/WSDtest/summaryAlerts/${deviceId}`)
        .pipe(
            catchError(this.handleError)
        );
    }

    GetTestData(deviceId:string):Observable<MMSHttpResponseGeneric<WSDTestData[]>>{
        return this.http.get<MMSHttpResponseGeneric<WSDTestData[]>>(environment.Tev_BaseUri+`/WSDtest/testdata/${deviceId}`)
        .pipe(
            catchError(this.handleError)
        );
    }
    private handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

}