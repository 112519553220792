<div class="grid-container">
    <h2>Test for device: {{deviceName}}</h2>
            <form (ngSubmit)="onSubmit()"#heroForm="ngForm">
                <div class="form-row">
                <div class="col-lg-3 col-md-6">
                    <label for="clearAir">Clear Air</label>
                    <input type="number" class="form-control" id="clearAir"
                           required
                           [(ngModel)]="testData.ClearAir" name="clearAir">

                  </div>
                  <div class="col-lg-3 col-md-6">
                    <label for="DriftBypass">Drift Bypass</label>
                    <input type="number" class="form-control" id="DriftBypass"
                           required
                           [(ngModel)]="testData.DriftBypass" name="DriftBypass">

                  </div>
                  <div class="col-lg-3 col-md-6">
                    <label for="DriftLimit">Drift Limit</label>
                    <input type="number" class="form-control" id="DriftLimit"
                           required
                           [(ngModel)]="testData.DriftLimit" name="DriftLimit">

                  </div>
                  <div class="col-lg-3 col-md-6">
                    <label for="GTemperatureSensorOffset">GTemperatureSensorOffset</label>
                    <input type="number" class="form-control" id="GTemperatureSensorOffset"
                           required
                           [(ngModel)]="testData.GTemperatureSensorOffset" name="GTemperatureSensorOffset">

                  </div>
                </div>
                <br/>
                <div class="form-row">
                    <div class="col-lg-3 col-md-6">
                        <label for="GTemperatureSensorOffset2">GTemperatureSensorOffset2</label>
                        <input type="number" class="form-control" id="GTemperatureSensorOffset2"
                               required
                               [(ngModel)]="testData.GTemperatureSensorOffset2" name="GTemperatureSensorOffset2">
    
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label for="IREDCalibration">IREDCalibration</label>
                        <input type="number" class="form-control" id="IREDCalibration"
                               required
                               [(ngModel)]="testData.IREDCalibration" name="IREDCalibration">
    
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label for="PhotoOffset">PhotoOffset</label>
                        <input type="number" class="form-control" id="PhotoOffset"
                               required
                               [(ngModel)]="testData.PhotoOffset" name="PhotoOffset">
    
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label for="TransmitResolution">TransmitResolution</label>
                        <input type="number" class="form-control" id="TransmitResolution"
                               required
                               [(ngModel)]="testData.TransmitResolution" name="TransmitResolution">
    
                      </div>
                      
                </div>
                <br/>
                <div class="form-row">
                    <div class="col-lg-3 col-md-6">
                        <label for="TransmitThreshold">TransmitThreshold</label>
                        <input type="number" class="form-control" id="TransmitThreshold"
                               required
                               [(ngModel)]="testData.TransmitThreshold" name="TransmitThreshold">
    
                      </div>
                      <div class="col-lg-3 col-md-6">
                            <label for="SmokeThreshold">SmokeThreshold</label>
                            <input type="number" class="form-control" id="SmokeThreshold"
                                   required
                                   [(ngModel)]="testData.SmokeThreshold" name="SmokeThreshold">
        
                          </div>
                </div>
                
                <br />
               
                  <button type="submit" style="margin-right:20px;" class="btn btn-primary" >Submit</button>
                  <a class="btn btn-secondary" (click)="clearForm()" >Clear</a>
            </form>
            <hr>
            
              <i (click)="refreshData()" class="fas fa-sync" style="font-size: 20px;
              color: lightseagreen;
              cursor: pointer;"></i>
            
            <table style="margin-top:20px" class="table table-striped">
              
                   <thead class="thead-dark">
                     <th>
                           Test Id
                            </th>
                     <th>
                     GTempSensorOffset2
                     </th>
                     <th>
                            GTempSensorOffset
                            </th>
                            <th>
                                   ClearAir
                            </th>
                            <th>
                                   IREDCalib
                            </th>
                            <th>
                                   PhotoOffset
                            </th>
                            <th>
                                   Drift ByPass
                            </th>
                            <th>
                                   Drift Limit
                            </th>
                            <th>
                                   Transmit R
                            </th>
                            <th>
                                   Transmit T
                            </th>
                            <th>
                                   Smoke Threshold
                            </th>
                            <th>
                                   Latest Smoke Value
                            </th>
                   </thead>
                   <tbody style="text-align: center;" >
                          <tr *ngFor="let data of tableData">
                            <td>{{data.id}}</td>
                             <td>{{data.gTemperatureSensorOffset2}}</td>
                             <td>{{data.gTemperatureSensorOffset}}</td>
                             <td>{{data.clearAir}}</td>
                             <td>{{data.iredCalibration}}</td>
                             <td>{{data.photoOffset}}</td>
                             <td>{{data.driftBypass}}</td>
                             <td>{{data.driftLimit}}</td>
                             <td>{{data.transmitResolution}}</td>
                             <td>{{data.transmitThreshold}}</td>
                             <td>{{data.smokeThreshold}}</td>
                             <td>{{data.smokeValue}}</td>
                             <td></td>
                             <td></td>
                          </tr>
                   </tbody>
            </table>
</div>