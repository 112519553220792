import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ReturnStatement } from '@angular/compiler';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CancelOrReactivateSubscriptionRequest } from 'src/app/model/cancel-reactivate-subscription-request';
import { Plan } from 'src/app/model/plan-request';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { ComputeCostRequest, CreateNewSubscriptionRequestOffline,CreateNewSubscriptionRequest, SubscriptionRequest, ZohoRetrieveSubscriptionResponse } from 'src/app/model/subscripition-request';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZohosubscriptionService {

  formData:any = new FormData();

  constructor(private http:HttpClient) { }

  getListofPlan(headless:boolean, product:string, deviceId:string):Observable<MMSHttpResponseGeneric<Plan[]>> {
    return this.http.get<MMSHttpResponseGeneric<Plan[]>>(environment.Zoho_BaseUri + "/GetPlans",{params: {headless: headless.toString(),product: product, deviceId:deviceId}})
    .pipe(tap((res: MMSHttpResponseGeneric<Plan[]>)=>{
      catchError(this.handleError);
    }))
  }

  createNewSubscriptionOnline(reqData:CreateNewSubscriptionRequest):Observable<MMSHttpResponseGeneric<string>>{
    
    return this.http.post<MMSHttpResponseGeneric<string>>(environment.Zoho_BaseUri + "/CreateNewSubscription",reqData)
      .pipe(tap((res: MMSHttpResponseGeneric<string>)=>{
        catchError(this.handleError);
      }))
  }

  updateDeviceTwinDesiredProperties(hostedpage_id: string, deviceId:string):Observable<any>{
    
    return this.http.get<any>(environment.Zoho_BaseUri + "/PushSubscriptionToDevice?hostedpageId="+ hostedpage_id+"&deviceId="+ deviceId)
      .pipe(tap((res:any)=>{
        catchError(this.handleError);
      }))
  }

  retrieveAllSubscription():Observable<any>{
    return this.http.get<any>(environment.Zoho_BaseUri+ "/RetrieveAllSubscription")
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  updateSubscriptionOnline(reqData:SubscriptionRequest):Observable<any>{
    return this.http.post<any>(environment.Zoho_BaseUri + "/UpdateSubscriptionOnline",reqData)
    .pipe(tap((res: any)=>{
      catchError(this.handleError);
    }))
  }

  updateSubscriptionOffline(reqData:SubscriptionRequest):Observable<any>{
    return this.http.post<any>(environment.Zoho_BaseUri + "/UpdateSubscriptionOffline",reqData)
    .pipe(tap((res: any)=>{
      catchError(this.handleError);
    }))
  }

  getSubscriptionDetailsById(subscriptionId : string):Observable<MMSHttpResponseGeneric<ZohoRetrieveSubscriptionResponse>>{
    return this.http.get<MMSHttpResponseGeneric<ZohoRetrieveSubscriptionResponse>>(environment.Zoho_BaseUri + "/SubscriptionDetail?subscriptionId=" + subscriptionId)
    .pipe(tap((res: MMSHttpResponseGeneric<ZohoRetrieveSubscriptionResponse>)=>{
      catchError(this.handleError);
    }))
  }

  //CancelSubscription
  cancelSubscription(data: CancelOrReactivateSubscriptionRequest):Observable<MMSHttpResponseGeneric<string>>{
    return this.http.post<MMSHttpResponseGeneric<string>>(environment.Zoho_BaseUri + "/CancelSubscription",data)
    .pipe(tap((res: MMSHttpResponseGeneric<string>)=>{
      catchError(this.handleError);
    }))
  }

  //ReactivateSubscription
  reactivateSubscription(data: CancelOrReactivateSubscriptionRequest):Observable<MMSHttpResponseGeneric<string>>{
    return this.http.post<MMSHttpResponseGeneric<string>>(environment.Zoho_BaseUri + "/ReactivateSubscription",data)
    .pipe(tap((res: MMSHttpResponseGeneric<string>)=>{
      catchError(this.handleError);
    }))
  }

  //ComputeCost
  computeCost(reqData:ComputeCostRequest):Observable<MMSHttpResponseGeneric<any>>{
    return this.http.post<MMSHttpResponseGeneric<any>>(environment.Zoho_BaseUri + "/ComputeCost",reqData)
    .pipe(tap((res: MMSHttpResponseGeneric<any>)=>{
      catchError(this.handleError);
    }))
  }

  getUnusedCredits():Observable<MMSHttpResponseGeneric<any>>{

    return this.http.get<MMSHttpResponseGeneric<any>>(environment.Zoho_BaseUri + "/GetUnusedCredits")
    .pipe(tap((res: MMSHttpResponseGeneric<any>)=>{
      catchError(this.handleError);
    }))
  }

  createNewSubscriptionOffline(reqData:CreateNewSubscriptionRequestOffline):Observable<MMSHttpResponseGeneric<string>>{
    
    return this.http.post<MMSHttpResponseGeneric<string>>(environment.Zoho_BaseUri + "/CreateNewSubscriptionOffline",reqData)
      .pipe(tap((res: MMSHttpResponseGeneric<string>)=>{
        catchError(this.handleError);
      }))
  }

  

  storeDeviceId(deviceId:string){
    
    localStorage.setItem(environment.DEVICE_ID, deviceId);
  }

  getDeviceId(){
    return localStorage.getItem(environment.DEVICE_ID);
  }


  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
