import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Org, UserAccount, User } from 'src/app/model/user';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MMSHttpResponse } from 'src/app/model/response';
import { AuthService } from '../../services/auth.service';
import { CustomValidators} from 'src/app/validators/custom-validators';
import { ErrorStateMatcher } from '@angular/material/core';



@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  userAccount : UserAccount = new UserAccount();
  orgDetails: Org = new Org();
  userDetails: User= new User();
  saveInProgress = false;
  pwdHide = true;
  cpwdHide = true;
  resData: MMSHttpResponse;
  user: FormGroup;
  org: FormGroup;
  account: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('Individual');
  isLinear = false;
  isOrg = false;
  hideInput = true;
  orgNameValidationErrorMsg: string;

  constructor(fb: FormBuilder, private _accountService: AuthService, private _router: Router,
    private _snackBar: MatSnackBar, private _validationService:CustomValidators) {

    this.account = fb.group({
      registerAs: ['Org', Validators.required],
    });
    this.org = fb.group({
      orgName:[null,{
        validators:[Validators.required,CustomValidators.specialCharacterValidator()],
        asyncValidators:[_validationService.orgNameUnique()],
        updateOn:'blur'
      }],
      orgDescription: [''],
      gstin:['',[Validators.required,CustomValidators.gstinValidator()]]
    });
    this.user = fb.group({
      firstName: ['', Validators.required,CustomValidators.firstNameValidator()],
      lastName: ['', Validators.required, CustomValidators.lastNameValidator()],
      email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required,CustomValidators.passwordPolicy()]],
      confirmPassword: ['', [Validators.required,CustomValidators.passwordSame()]],
      mobileNumber: ['', [Validators.required,CustomValidators.mobileNumberValidator() ]]
    });
   //, {validator: this.checkPasswords }
  }

  ngOnInit(): void {
  }

  onChange() {
    if (this.account.value.registerAs === 'Org')
      this.isOrg = true;
    else
      this.isOrg = false;
  }

  resetRegForm(){
    this.org.reset();
    this.user.reset();
    this.account.reset();
    this.isOrg = false;
  }

  form1() {
    // stop here if form is invalid
    if (this.account.invalid) {
      return;
    }
    this.userAccount.appName = environment.appName;
    this.userAccount.registerAs = this.account.value.registerAs;
  }

  form2() {
    // stop here if form is invalid
    if (this.org.invalid) {
      return;
    }
    this.orgDetails.orgName = this.org.value.orgName;
    this.orgDetails.orgDescription = this.org.value.orgDescription;
    this.orgDetails.gstin = this.org.value.gstin;
    this.userAccount.org =  this.orgDetails;
  }

  form3() {
    // stop here if form is invalid
    if (this.user.invalid) {
      return;
    }
    console.log(this.user.value);
    this.userDetails.firstName = this.user.value.firstName;
    this.userDetails.lastName = this.user.value.lastName;
    this.userDetails.email = this.user.value.email;
    this.userDetails.mobileNumber = this.user.value.mobileNumber;
    this.userDetails.password = this.user.value.password;
    this.userAccount.user = this.userDetails;
    this.createUserAccount(this.userAccount);
  }

  createUserAccount(data:UserAccount) {
    
    this.saveInProgress = true;
    console.log("registerDAta",data);
    
    this._accountService.createAccount(data).subscribe((res) => {
      this.resData = res;
      console.log("data", this.resData);
      if (this.resData.successMessage = "User created successfully. Confirmation email sent to user's email") {
        this._router.navigateByUrl('/login');
        this._snackBar.open(this.resData.successMessage, '', {
          duration: 5000
        });
        this.saveInProgress = false;
      }
      else {
        this._snackBar.open(this.resData.errorMessage, '', {
          duration: 5000
        });
        this.saveInProgress = false;
      }
    }, err => {
      this._snackBar.open(err.error.errorList[0], 'error!', {
        duration: 5000,
      });
      console.log(err);
      this.saveInProgress = false;
    });


  }

  checkPasswords(group: FormGroup) { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPass').value;

    return pass === confirmPass ? null : { notSame: true }     
  }

}
