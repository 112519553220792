export class SubscriptionRequest{
    subscriptionId:string;
    planCode:string;
    deviceId:string;
    addons:string[];
    headless:boolean;
}

export class CreateNewSubscriptionRequest{
    planCode:string;
    deviceId:string;
    addons:string[];
    headless:boolean;
}

export class CreateNewSubscriptionRequestOffline{
    planCode:string;
    deviceId:string;
    addons:string[];
    headless:boolean;
    secretkey:string;
}

export class ComputeCostRequest{
    subscriptionId:string;
    planCode:string;
    addons:string[];
}

export interface ZohoRetrieveSubscriptionResponse
    {
        subscriptionId : string;
        name: string;
        status: string;
        createdDate: string;
        activatedDate: string;
        expiryDate: string;
        interval : number;
        intervalUnit : number;
        billingMode: string;
        productName: string;
        productId: string;
        subTotal: string;
        amount: string;
        plan: ZohoPlan;
        addOns: ZohoAddon[];
        taxes: ZohoTax[];

    }
export class ZohoPlan
    {
        planCode: string;
        name: string;
        description: string;
        price: string;
        quantity: string;
    }
    
export class ZohoAddon
    {
        addOnCode: string;
        name: string;
        description: string;
        price: string;
        quantity: string;
    }

export class ZohoTax
    {
        id: string;
        name: string;
        amount: string;
    }