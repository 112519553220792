export class Device {
    id:string;
    name:string;
    connected:boolean;
    locationId:string;
    locationName:string;
    macAddress:string;
    firmwareVersion:string;
    wifiName:string;
    subscriptionId:string;
    availableFeatures:string [];
    subscriptionExpiryDate:Date;
    subscriptionStatus:string;
    deviceType: string;
    disabled:boolean;
    videoLoading:boolean;
    videoShow:boolean;
    player:any;

}
