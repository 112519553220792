import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { FileParameter, UserDetailsResponse } from 'src/app/model/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient, private _authService: AuthService) { }

  uploadImage(uploadedFile: FileParameter):Observable<MMSHttpResponseGeneric<string>> {
    
   
    const formData = new FormData();

    if (uploadedFile === null || uploadedFile === undefined)
        throw new Error("The parameter 'uploadedFile' cannot be null.");
    else
    formData.append("file", uploadedFile.data);

    return this.http.post<MMSHttpResponseGeneric<string>>(environment.authBaseUrl + "/api/user/UploadProfilePicture",formData)
    .pipe(tap((res: MMSHttpResponseGeneric<string>)=>{
      catchError(this.handleError);
    }))
  }

  getUserDetails(): Observable<UserDetailsResponse>{
    return this.http.get<UserDetailsResponse>(environment.authBaseUrl+'/api/user/UserDetails')
    .pipe(
      catchError(this.handleError)
    );
  }

  updateUserDetails(data): Observable<MMSHttpResponseGeneric<string>>{
    return this.http.post<MMSHttpResponseGeneric<string>>(environment.authBaseUrl+'/api/user/updateProfile', data)
    .pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
