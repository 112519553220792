import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Device } from 'src/app/model/device';
import { PeopleCount } from 'src/app/model/peopleCount';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http:HttpClient) { }

  retireveAllDevice():Observable<MMSHttpResponseGeneric<Device[]>>{
    return this.http.get<MMSHttpResponseGeneric<Device[]>>(environment.Tev_BaseUri+ "/Devices")
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetPeopleCount(deviceId:string):Observable<MMSHttpResponseGeneric<PeopleCount>>{
    return this.http.get<MMSHttpResponseGeneric<PeopleCount>>(environment.Tev_BaseUri+ "/Devices/GetPeopleCount/" + deviceId +"?skip=0&take=1000")
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetAllLocations():Observable<MMSHttpResponseGeneric<any[]>>{
    return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+"/Location")
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetAuthCode(purpose:string,logicalDeviceId:string):Observable<MMSHttpResponseGeneric<string>>{
    return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+"/Devices/QRAuthCode?deviceId=" + logicalDeviceId + "&purpose=" + purpose)
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetSetupStatus(logicalDeviceId:string):Observable<MMSHttpResponseGeneric<any>>{
    return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri.substring(0,environment.Tev_BaseUri.length-2)+"Notification/deviceSetupStatus?logicalDeviceId=" + logicalDeviceId)
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetPicture(logicalDeviceId:string):Observable<MMSHttpResponseGeneric<any>>{
    return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+"/Devices/GetZoneFencing/" + logicalDeviceId +"?clientImageWidth=300&clientIMageHeight=300")
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  DeleteDevice(logicalDeviceId:string):Observable<MMSHttpResponseGeneric<any>>{
    return this.http.delete<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+"/Devices/DeleteDevice/" + logicalDeviceId)
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetDeviceType(logicalDeviceId:string):Observable<MMSHttpResponseGeneric<any>>{
    return this.http.get<MMSHttpResponseGeneric<any[]>>(environment.Tev_BaseUri+"/Devices/GetDeviceType/" + logicalDeviceId)
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  GetDeviceByDeviceId(logicalDeviceId:string):Observable<MMSHttpResponseGeneric<Device>>{
    return this.http.get<MMSHttpResponseGeneric<Device>>(environment.Tev_BaseUri+ "/Devices/GetDeviceByDeviceId/" + logicalDeviceId)
    .pipe(tap((res:any)=>{
      catchError(this.handleError);
    }))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
