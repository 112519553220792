import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DeviceService } from 'src/app/pages/services/device.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'device-image',
  templateUrl: './device-image.component.html',
  styleUrls: ['./device-image.component.scss'],

})
export class DeviceImageComponent implements OnInit {
  QrCode="";
  qrCodeButton=false;
  deleteInProgress=false;
  ngOnInit(): void {
    
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data, public deviceService:DeviceService,private _snackBar: MatSnackBar){

  }
   FactoryReset(){
     this.deviceService.GetAuthCode("factoryreset",this.data.deviceId).subscribe(x=>{
      var data = {
        "a": "r",
        "t": x.responseBody,      
        "ldi": this.data.deviceId,
      }
      console.log(data);
      this.QrCode = JSON.stringify(data);
      this.qrCodeButton=true;
     })
   }

   DeleteDevice(){
     this.deleteInProgress=true;
     this.deviceService.DeleteDevice(this.data.deviceId).subscribe(x=>{
       this.deleteInProgress=false;
       this._snackBar.open("Device reset to factory version");
     },err=>{
       this.deleteInProgress=false;
     })
   }

}