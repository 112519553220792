import { Component, OnInit,Input } from '@angular/core';
import { DeviceAlert } from 'src/app/model/device-alert';

@Component({
  selector: 'app-device-alert-card',
  templateUrl: './device-alert-card.component.html',
  styleUrls: ['./device-alert-card.component.scss']
})
export class DeviceAlertCardComponent implements OnInit {

  @Input() alert: DeviceAlert;
  public occurDate="";

  constructor() {
    
   }

  ngOnInit(): void {
    this.occurDate=new Date(<number><unknown>this.alert.occurenceTimeStamp*1000).toLocaleString("en-IN");
  }

}
