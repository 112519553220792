import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WSDTestData } from 'src/app/model/WSDTestData';
import { GlobalService } from '../../services/global.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { WSDTestService } from '../../services/wsd-test.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { pathToFileURL } from 'url';

@Component({
    selector:'wsd-summary',
    templateUrl:'./wsd-summary.html',
    styleUrls:['./wsd-summary.scss']
})
export class WSDSummary implements OnInit{
    public deviceId:string;
    public deviceName:string;
    public summaryData:any[]=[];
    
    constructor(private route: ActivatedRoute,private global:GlobalService,private testService:WSDTestService,private _snackBar:MatSnackBar){
        this.route.queryParams.subscribe(x=>{
            this.deviceId=x["deviceId"];
            this.deviceName=x["deviceName"];
        });
      
        

    }
    ngOnInit(): void {
    console.log(this.deviceId);
    console.log(this.deviceName);
    this.testService.GetSummaryData(this.deviceId).subscribe(x=>{
    x.responseBody.map(y=>{
        var alertType="";
        if(y.alertType==100){
            alertType="Fire"

        }
        if(y.alertType==101){
            alertType="Test"

        }
        if(y.alertType==102){
            alertType="Offline"

        }
        if(y.smokeStatus==3){
            alertType="Parameter Test id="+y.testId
        }
         this.summaryData.push( {
            time:new Date(y.enqueuedTimestamp*1000).toLocaleString("en-IN"),
            sv:y.smokeValue,
            ss:y.smokeStatus,
            bv:y.batteryValue,
            bs:y.batteryStatus,
            at:alertType

         });
     });
     console.log(this.summaryData)
    })
    }

    refreshData(){
        this.testService.GetSummaryData(this.deviceId).subscribe(x=>{
            this.summaryData=[];
            x.responseBody.map(y=>{
                var alertType="";
                if(y.alertType==100){
                    alertType="Fire"
        
                }
                if(y.alertType==101){
                    alertType="Test"
        
                }
                if(y.alertType==102){
                    alertType="Offline"
        
                }
                if(y.smokeStatus==3){
                    alertType="Parameter Test id="+y.testId
                }
                 this.summaryData.push( {
                    time:new Date(y.enqueuedTimestamp*1000).toLocaleString("en-IN"),
                    sv:y.smokeValue,
                    ss:y.smokeStatus,
                    bv:y.batteryValue,
                    bs:y.batteryStatus,
                    at:alertType
        
                 });
             });
            this._snackBar.open("Refreshed","",{
                duration:2000
            })
           },err=>{
            this._snackBar.open("Error Occured!","",{
                duration:2000
            })
           })
    }

}