import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Url } from 'url';

@Component({
  selector: 'app-hosted-page',
  templateUrl: './hosted-page.component.html',
  styleUrls: ['./hosted-page.component.scss']
})
export class HostedPageComponent implements OnInit {

  url:string = ''
  hostedPageUri:SafeUrl
  loaded=false;

  constructor(private route: ActivatedRoute,private dom:DomSanitizer) { 
    if(this.route.snapshot.queryParams['hostedpageUri']){
        this.url = route.snapshot.queryParams.hostedpageUri;
        this.hostedPageUri =this.dom.bypassSecurityTrustResourceUrl(this.url); 
        console.log(this.url);
    }
  }

  ngOnInit(): void {
  }

  hideLoader():void{
    setTimeout(() => {
      this.loaded=true;
    }, 4000);
   
  }

}
