import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';
import { MMSHttpResponse, MMSHttpResponseGeneric } from '../model/response';
import { AuthToken } from '../model/authtoken';
import { CommonModule } from '@angular/common';
import { GlobalService } from '../pages/services/global.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ZohosubscriptionService } from '../pages/services/zohosubscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  logoutInProgress:boolean=false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public ununsedCredit : string = "0";
  constructor(private breakpointObserver: BreakpointObserver,private _authService:AuthService, private _router:Router, 
    public _global:GlobalService,private _zohoSubscriptionService: ZohosubscriptionService) {}

  ngOnInit(): void {
  }

  logOut(){
    this.logoutInProgress=true;
    let clientId = this._authService.getClientId();
    if(clientId == environment.clientId){
      this._authService.logout().subscribe(
        (res:MMSHttpResponse)=>{
          if(res.successMessage === "Logged out successfully"){
            console.log("success",res);
            this._authService.doLogoutUser();
            this._router.navigateByUrl('/login');
          }
          else{
            alert("error");
          }
          this.logoutInProgress=false;
      },error=>{
        console.log("error",error);
        this.logoutInProgress=false;
      }
      );
    }else{
      this._authService.doLogoutUser();
      this._router.navigateByUrl('/login');
    }
    
  }

  getUnusedCredits(){
    this._zohoSubscriptionService.getUnusedCredits()
      .subscribe((res: MMSHttpResponseGeneric<any>) => {
        if (res.successMessage == "success") 
          this.ununsedCredit = res.responseBody;
        else 
          console.log(res.errorMessage);
      }, err => {
        console.log(err);
      });
  }

}
