import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AuthToken } from 'src/app/model/authtoken';
import { MMSHttpResponse, MMSHttpResponseGeneric } from 'src/app/model/response';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { ChangePasswordService } from '../../services/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePwdForm: FormGroup;
  crntpwdHide = true;
  newpwdHide = true;
  confirmpwdHide = true;
  loading = false;
  resData: MMSHttpResponse;

  constructor(fb:FormBuilder, private _changePasswordService: ChangePasswordService,private _authService:AuthService,
    private _router: Router, private _snackBar:MatSnackBar, private breakpointObserver: BreakpointObserver) { 
    this.changePwdForm = fb.group({
      currentPassword: ['', Validators.required],
      password: ['', [Validators.required,CustomValidators.passwordPolicy()]],
      confirmPassword: ['', [Validators.required,CustomValidators.passwordSame()]]
    })
  }

  ngOnInit(): void {

  }

  onSubmit(){
    if(this.changePwdForm.invalid){
      return false;
    }

    this.loading = true; 
    this._changePasswordService.changeUserPassword(this.changePwdForm.value.currentPassword,this.changePwdForm.value.password).subscribe((res: MMSHttpResponse) => {
      this.resData = res;
      console.log("data", this.resData);
      if (this.resData.successMessage !== null) {
        this._router.navigateByUrl('/login');
        this._snackBar.open(this.resData.successMessage, '', {
          duration: 5000
        });
        this.loading = false;
        this.changePwdForm.reset();
      }
      else {
        this._snackBar.open(this.resData.errorMessage, '', {
          duration: 5000
        });
        this.loading = false;
      }
    }, err => {
      this._snackBar.open(err.error.errorMessage,'', {
        duration: 5000,
      });
      this.loading = false;
    });

  }

  logOut(){
    this._authService.logout().subscribe((res:MMSHttpResponse)=>{
      
        if(res.errorMessage==null){
          this._authService.doLogoutUser();
          this._router.navigateByUrl('/login');
        }
        else{
          alert("error");
        }
    });
  }
}
