import { Component, OnInit } from '@angular/core';
import { CollectionViewer, DataSource} from '@angular/cdk/collections';
import { DeviceAlert } from 'src/app/model/device-alert';
import { DeviceAlertService } from '../../device-alert.service';
import { BehaviorSubject } from 'rxjs';
import { MMSHttpResponseGeneric } from 'src/app/model/response';

@Component({
  selector: 'app-device-alert-feed',
  templateUrl: './device-alert-feed.component.html',
  styleUrls: ['./device-alert-feed.component.scss']
})
export class DeviceAlertFeedComponent implements OnInit {

  pageLoader :boolean;
  deviceAlertList: DeviceAlert[];
  test:string = "hello this is alert feed";
  constructor(private _deviceAlertService:DeviceAlertService) { }

  ngOnInit(): void {
    this.getdeviceAlertList();
  }

  getdeviceAlertList(){
    this.pageLoader = true;
    this._deviceAlertService.retireveAllDeviceAlert(10,0)
    .subscribe(
      (res:MMSHttpResponseGeneric<DeviceAlert[]>)=>{
        this.deviceAlertList = res.responseBody;
        console.log("devices",this.deviceAlertList);
        this.pageLoader = false;
    },
    error=>{
      console.log(error);
    })
  }

}
