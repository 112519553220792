export const environment = {
    production: false,
    authBaseUrl : "https://mmsidentitydev.honeywell.com",
    appName: "TEV",
    clientId:"tev.webui",
    resetPasswordRedirectUri:"https://tevwebdev.honeywell.com/reset-password",
    inviteUserRedirectUri: "https://tevwebdev.honeywell.com/invite-confirm",
    
    JWT_TOKEN:'AUTH_TOKEN',
    REFRESH_TOKEN:'REFRESH_TOKEN',
    CLIENT_ID:'CLIENT_ID',
  
    ZOHO_TOKEN:'ZOHO_TOKEN',
    DEVICE_ID:'DEVICE_ID',
  
    // Zoho related keys
    Zoho_BaseUri:"https://tevapidev.honeywell.com/api/v1/ZohoSubscription",
     //Tev Uri
     Tev_BaseUri:"https://tevapidev.honeywell.com/api/v1",
     Mask:4,
     NoMask:6,
     OfflineSubscriptionSecretkey:"24365248723949023gvjhnskldfbsdvfjhdbshdvfhdbfjdsbiw4y98q3u40e23",

     //TEV2 Promo plan code 
     Plan_Code:503
  };
  