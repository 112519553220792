import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import { DeviceService } from '../../services/device.service';
import {environment} from "../../../../environments/environment";
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { PeopleCount } from 'src/app/model/peopleCount';

@Component({
    selector: 'people-count',
    templateUrl: './people-count.html',
    styleUrls: ['./people-count.scss']
})
export class PeopleCountComponent implements OnInit {
    deviceId: string = "";
    deviceName: string = "";
    location: string = "";
    width;
    height;
    updateFromInput = false;
    accessToken:string="";
    loading = true;
    nodata = false;

    Highcharts: typeof Highcharts = Highcharts;
    isHighcharts = typeof Highcharts === 'object';
    chartOptions: Highcharts.Options = {
        time: {
            timezoneOffset: -5.5 * 60
        },
        chart: {
            zoomType: 'x',
            type: "column"
        },

        title: {
            text: 'People count over time'
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            allowDecimals:false,
            title: {
                text: 'People Count'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba").toString()]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },

        series: [{
            type: 'column',
            name: 'No of people',
            data: [
                [

                    0, 0
                ]]
        }]
    };

    data: number[][] = [];
    constructor(private activatedRoute: ActivatedRoute, private deviceService: DeviceService) {
        activatedRoute.queryParams.subscribe(x => {
            this.deviceId = x["deviceId"];
            this.accessToken=x["accessToken"];
        })

    }
    ngOnInit(): void {
        localStorage.setItem(environment.CLIENT_ID,"tev.mobileApp");
        localStorage.setItem(environment.JWT_TOKEN,this.accessToken);
        this.width = window.innerWidth-10;
        this.height = window.innerHeight-70;
        console.log(window);

        this.deviceService.GetPeopleCount(this.deviceId).subscribe((x:MMSHttpResponseGeneric<PeopleCount>)=> {
            let i = 0;
            this.loading = false;
            if(x.responseBody!= null){
                this.deviceName = x.responseBody.deviceName;
                this.location = x.responseBody.locationName;
            }
            if (x.responseBody.peopleCountList.length == 0) {
                this.nodata = true;
                return;
            }
            x.responseBody.peopleCountList.forEach(x => {
                this.data[i] = [x.occurenceTimestamp * 1000, x.peopleCount];
                i++;

            });
           this.AllData();
        });

    }

    AggregateDay() {
       

            this.updateFromInput=true;
            Highcharts.charts[0].xAxis[0].setExtremes(null,null)
            if (this.data.length == 0) {
                return;
            }
            
            let temp: any[][] = [];
            this.data.reduce((previous, current, index, array) => {
                if (index == 0) {
                    return [current[0], current[1]]
                }
                var date = new Date(previous[0]);
                var compDate = new Date(current[0]);
                if (date.getDate() == compDate.getDate() && date.getMonth() == compDate.getMonth() && date.getFullYear() == compDate.getFullYear()) {
                    if (index == array.length - 1) {
                        let dateStringOnly = compDate.toDateString();
                        let dateOnly = new Date(dateStringOnly);
                        temp.push([dateOnly.getTime(), previous[1] + current[1]])
                    }
                    return [current[0], previous[1] + current[1]]
                } else {
                    
                    let dateStringOnly = date.toDateString();
                    let dateOnly = new Date(dateStringOnly);
                    temp.push([dateOnly.getTime(), previous[1]]);
                    if(index==array.length-1){
                        
                        let dateStringOnly = compDate.toDateString();
                        let dateOnly = new Date(dateStringOnly);
                        temp.push([dateOnly.getTime(),  current[1]]);
                        
                        }
                    return current;
                }
    
    
            }, this.data[0]);
            console.log(temp);
            
            this.chartOptions = Highcharts.setOptions({
                time: {
                    timezoneOffset: -5.5 * 60
                },
                chart: {
                    zoomType: 'x',
                    type: "column"
                },
    
                title: {
                    text: `People count for ${this.deviceName} installed at ${this.location}`
                },
                subtitle: {
                    text: document.ontouchstart === undefined ?
                        'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                },
                xAxis: {
                    type: 'datetime',
                    minTickInterval: 1
    
                },
                yAxis: {
                    allowDecimals:false,
                    title: {
                        text: 'People Count'
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba").toString()]
                            ]
                        },
                        marker: {
                            radius: 8
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
    
                series: [{
                    type: 'column',
                    name: 'No. of people',
                    data: temp
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -5
                                },
                                title: {
                                    text: null
                                }
                            },
                            subtitle: {
                                text: null
                            },
                            credits: {
                                enabled: false
                            },
    
                        }
                    }]
                }
            });
    

       

       

       




    }

    AllData() {
        this.chartOptions = Highcharts.setOptions({
            time: {
                timezoneOffset: -5.5 * 60
            },
            chart: {
                zoomType: 'x',
                type: "column"
            },
            title: {
                text: `People count for ${this.deviceName} installed at ${this.location}`
            },
            subtitle: {
                text: document.ontouchstart === undefined ?
                    'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                allowDecimals:false,
                title: {
                    text: 'People Count'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba").toString()]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },

            series: [{
                type: 'column',
                name: 'No. of people',
                data: this.data
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        },

                    }
                }]
            }
        });
        this.updateFromInput = true;
    }

}
