import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Device } from '../model/device';
import { DeviceAlert } from '../model/device-alert';
import { MMSHttpResponse, MMSHttpResponseGeneric } from '../model/response';
import { WSDAlert } from '../model/WSDAlerts';

@Injectable({
  providedIn: 'root'
})
export class DeviceAlertService {

  constructor(private http: HttpClient) { }

  retireveAllDeviceAlert(take:number,skip:number):Observable<MMSHttpResponseGeneric<DeviceAlert[]>>{
    return this.http.post<MMSHttpResponseGeneric<DeviceAlert[]>>(environment.Tev_BaseUri+ "/Alerts",{"take":100,"skip":0})
    .pipe(tap((res:MMSHttpResponseGeneric<DeviceAlert[]>)=>{
      catchError(this.handleError);
    }))
  }

  retireveWSDAlerts():Observable<MMSHttpResponseGeneric<WSDAlert[]>>{
    return this.http.post<MMSHttpResponseGeneric<WSDAlert[]>>(environment.Tev_BaseUri+ "/Alerts",{"take":50,"skip":0,"device":"WSD"})
    .pipe(tap((res:MMSHttpResponseGeneric<WSDAlert[]>)=>{
      catchError(this.handleError);
    }))
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
