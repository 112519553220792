
    <mat-spinner style="width: 100px;
    height: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);" color="warn" *ngIf="loading"></mat-spinner>
    <highcharts-chart [hidden]="loading || nodata" [ngStyle]="{'width':width +'px','height':height+'px','display':'block'}" [(update)]="updateFromInput"  [Highcharts]="Highcharts" [options]="chartOptions" ></highcharts-chart>

    <div [hidden]="loading || nodata" style="text-align: center;margin-top:10px;">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="bold" selected (click)="AllData()">All Data</mat-button-toggle>
            <mat-button-toggle value="italic" (click)="AggregateDay()">Daily Aggregate</mat-button-toggle>
          </mat-button-toggle-group>
    </div>
    <div *ngIf="nodata" style="font-family: 'Ubuntu';
    font-size: 22px;
    text-align: center;
    margin-top: 60px;padding:30px;line-height: 34px;">
    <div>No people count data availabe for this device<div> <i style="margin-top: 100px;
        font-size: 150px;
        opacity: 0.6;
        color: #f1623b;" class="fas fa-eye-slash"></i></div>
   

