import { Component,OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { DeviceAlertService } from '../../device-alert.service';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { DeviceAlert } from 'src/app/model/device-alert';
import { CommonModule } from '@angular/common';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";
import { WSDAlert } from 'src/app/model/WSDAlerts';
import { GlobalService } from '../../services/global.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
};


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  
  pageLoader = false;
  deviceAlertList: WSDAlert[];

  constructor(private breakpointObserver: BreakpointObserver,private _deviceAlertService : DeviceAlertService, private _globalService:GlobalService ) {
    this._globalService.viewTitle="Alerts"
  }
  ngOnInit(): void {
    this.getWSDAlerts();
    
  }

  getWSDAlerts(){
    this.pageLoader = true;
    this._deviceAlertService.retireveWSDAlerts()
    .subscribe(
      (res:MMSHttpResponseGeneric<WSDAlert[]>)=>{
        this.deviceAlertList = res.responseBody.reverse();
        console.log("WSD Alerts",this.deviceAlertList);
        this.pageLoader = false;
        this.fillChartData();
    },
    error=>{
      console.log(error);
    })
  }

  fillChartData(){
    this.chartOptions = {
      series: [
        {
          name: "Smoke Value",
          type: "line",
          data: this.deviceAlertList.map(x=>x.sv),
        },
        {
          name: "Battery Value",
          type: "line",
          data: this.deviceAlertList.map(x=>x.bv)
        },
        {
          name: "Battery Status",
          type: "line",
          data: this.deviceAlertList.map(x=>x.bs)
        },
        {
          name: "Smoke Status",
          type: "line",
          data: this.deviceAlertList.map(x=>x.ss)
        }
      ],
      chart: {
        height: 350,
        type: "line"
      },
      stroke: {
        width: [2, 2,2,2]
      },
      title: {
        text: "WSD Last 50 alerts",
        style:{
          fontFamily:"Roboto",
          color:"#666"
        }
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [0,1]
      },
      labels: this.deviceAlertList.map(x=>new Date(x.enqueuedTime*1000).toLocaleTimeString()),
      xaxis: {
        type: "category"
      },
      yaxis: [
        {
          title: {
            text: "Smoke Value",
            style:{
              fontFamily:"Roboto",
              fontWeight:500
            }
          }
        },
        {
          opposite: true,
          title: {
            text: "Battery Value",
            style:{
              fontFamily:"Roboto",
              fontWeight:500
            }
          }
        },
        {
          title: {
            text: "Battery Status",
            style:{
              fontFamily:"Roboto",
              fontWeight:500
            }
          }
        },
        {
          opposite:true,
          title: {
            text: "Smoke Status",
            style:{
              fontFamily:"Roboto",
              fontWeight:500
            }
          }
        }
      ]
    };
  }
}
