import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { UserDetailsResponse } from 'src/app/model/user';
import { GlobalService } from 'src/app/pages/services/global.service';
import { UserService } from 'src/app/pages/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {


  fileToUpload: File = null;
  files: Array<any> = new Array<any>();
  url: string = '';
  uploadResponse : any;
  userDetails: UserDetailsResponse = new UserDetailsResponse();
  btnUserEditLoader:boolean = false;
  currentImgurl : string;

  constructor(private _snackBar:MatSnackBar,private _userService: UserService, private _global:GlobalService) { 
   
  }

  ngOnInit(): void {
    this.getUserDetails();
    this._global.viewTitle="My Profile";
  }

  getUserDetails(){
    this._userService.getUserDetails()
    .subscribe(
      (res:UserDetailsResponse) => {
      this.userDetails = res;
      if(this.userDetails.pictureUrl!= null)
        this.url = this.userDetails.pictureUrl;
        this.currentImgurl = this.userDetails.pictureUrl;
      console.log("response",res);
    },
    error =>{
        console.log("error",error);
    })
  }

  onSubmit(){
    this.btnUserEditLoader = true;
    this.updateUserInfo({"firstName":this.userDetails.firstName,"lastName": this.userDetails.lastName,"phoneNumber":this.userDetails.phoneNumber});
  }

  onSelectFile(files: FileList) {

    if (files.length === 0)
      return;
  
    this.fileToUpload = files.item(0);
  
  
    const fileReader: FileReader = new FileReader();
    
  
    this.files.push({ data: this.fileToUpload, fileName: this.fileToUpload.name });

    if(!this.validateFile(this.files[this.files.length-1].fileName)){
      this.url = this.currentImgurl;
      this._snackBar.open("Selected file format is not supported. please, upload jpg,jpeg,png", 'error!!', {
        duration: 5000
      });
    }
    else{
      
      fileReader.readAsDataURL(this.fileToUpload);
      fileReader.onload = (event: any) => {
        this.url = event.target.result;
      };
      this._userService.uploadImage(this.files[0])
      .subscribe((result: MMSHttpResponseGeneric<string>) => {
        if(result.errorList.length == 0){
          this._snackBar.open("profile image updated successfully", 'success', {
            duration: 5000
          });
        }else{
          this._snackBar.open(result.errorMessage, 'error!!', {
            duration: 5000
          });
        }
      },
      error=> {
        this._snackBar.open("error occured, please try again later", 'error!!', {
          duration: 5000
        });
        console.log("error in profile pic upload ",error);
     
      }  
    );
    }
  
   
  }
  
  delete() {
    this.url = null;
  }

  updateUserInfo(data) {
    this._userService.updateUserDetails(data)
    .subscribe((res:MMSHttpResponseGeneric<string>) =>{

      if(res.errorList.length == 0){
        this._snackBar.open(res.successMessage, 'success', {
          duration: 5000
        });
      }else{
        this._snackBar.open(res.errorMessage, 'error!!', {
          duration: 5000
        });
      }
      this.btnUserEditLoader = false;
    },
    error=> {
      this._snackBar.open("error occured, please try again later", 'error!!', {
        duration: 5000
      });
      console.log("error",error);
      this.btnUserEditLoader = false;
    })
  }

  validateFile(name: String): boolean {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg') {
        return true;
    }
    else {
        return false;
    }
  }

}
