import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) { }

    canActivate() {
        
        if (this._authService.isLoggedIn()) {
            this._router.navigateByUrl('/page/device-list');
        }
        return !this._authService.isLoggedIn();
    }
}