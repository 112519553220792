<img src="../../../../assets/logo/IMPACT-by-honeywell.png" style="height:30px;margin:10px;"/>
<div style="width:100%;height:calc(100% - 50px);">
    <iframe id="hostedPage" name="hostedPage" [src]="hostedPageUri" scrolling="yes" frameborder="0" style="width: 100%;
    border: none;
    height: 100%;" (load)="hideLoader()"></iframe>
</div>
<mat-spinner style="width: 100px;
    height: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);" color="warn" *ngIf="!loaded"></mat-spinner>