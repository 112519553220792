<div class="grid-container">
       <h2>Data for device: {{deviceName}}  <i (click)="refreshData()" class="fas fa-sync" style="font-size: 20px;
              color: lightseagreen;
              cursor: pointer;"></i></h2>
      
    
            <table style="margin-top:20px" class="table table-striped">
              
                   <thead class="thead-dark">
                     <th>
                           Date Time(IST)
                            </th>
                     <th>
                     Smoke Value
                     </th>
                     <th>
                            Smoke Status
                            </th>
                            <th>
                                   Battery Value
                            </th>
                            <th>
                                   Battery Status
                            </th>
                            <th>
                                  Alert Type
                            </th>
                           
                   </thead>
                   <tbody style="text-align: left;" >
                          <tr *ngFor="let data of summaryData">
                            
                             <td>{{data.time}}</td>
                             <td>{{data.sv}}</td>
                             <td>{{data.ss}}</td>
                             <td>{{data.bv | number : '1.2-2'}}</td>
                             <td>{{data.bs}}</td>
                             <td>{{data.at}}</td>
                           
                          </tr>
                   </tbody>
            </table>
</div>