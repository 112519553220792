
export class User {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    password: string;
    email: string;
}

export class Org {
    orgName: string;
    orgDescription: string;
    gstin:string;
}

export class UserAccount {
    user: User;
    org: Org;
    appName: string;
    registerAs: string;
}

export interface FileParameter {
    data: any;
    fileName: string;
}

export class UserDetailsResponse{
    firstName:string;
    lastName:string;
    email:string;
    pictureUrl:string;
    regType:any;
    orgName:string;
    description:string;
    gstin:string;
    phoneNumber:string;
    role:string;
    zohoId?:string;
    orgId:string;
}
