<div class="grid-container">
  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div style="box-shadow: 0 0 4px 0px rgba(0,0,0,0.4);
      padding: 10px;
      border-radius: 3px;">
      <div style="margin-bottom: 10px;color: #666;">TEV Alerts</div>
    <app-device-alert-feed></app-device-alert-feed>
  </div>
    </div>
  </div>
</div>
