<div style="text-align: center;">
  <img [hidden]="qrCodeButton" width="200px" src="{{data.imgUrl}}">
  <qrcode [qrdata]="QrCode" [errorCorrectionLevel]="'L'" allowEmptyString="true" [elementType]="'svg'" [margin]="0"></qrcode>
  <button [hidden]="qrCodeButton" class="login-button" mat-flat-button style="margin-top:40px" (click)="FactoryReset()" >Generate QR code
    
  </button>

  <div [hidden]="!qrCodeButton" style="margin-top:45px;">
  <p>Scan the QR code using the camera, then only click the button below</p>
  <button [disabled]="deleteInProgress" color="primary" mat-flat-button (click)="DeleteDevice()">
    Reset Device
    <mat-icon *ngIf="deleteInProgress">
      <mat-spinner color="primary" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
</div>
</div>