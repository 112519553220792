<div class="grid-container">
  <div class="row">
    <div class="col-lg-4">
      <mat-card style="text-align: center;">
        <mat-card-content>
          <form #heroForm="ngForm" (ngSubmit)="onSubmit()">
            <div style="margin-bottom: 30px;">
              <img [src]="url ? url : ''" class="profile-image" alt="User Image">
              <input #fileInput id="fileInput" type='file' (click)="fileInput.value=null" value="Change Picture"
                (change)="onSelectFile($event.target.files)">
            </div>
            <mat-form-field>
              <mat-label>Firstname</mat-label>
              <input matInput class="form-control" placeholder="firstname" required name="userDetails.firstName"
                [(ngModel)]="userDetails.firstName">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Lastname</mat-label>
              <input matInput class="form-control" placeholder="lastname" required name="userDetails.lastName"
                [(ngModel)]="userDetails.lastName">
            </mat-form-field>


            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput class="form-control" placeholder="email" readonly name="userDetails.email"
                [(ngModel)]="userDetails.email">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input matInput class="form-control" placeholder="phonenumber" required name="userDetails.phoneNumber"
                [(ngModel)]="userDetails.phoneNumber" >
            </mat-form-field>



            <mat-form-field>
              <mat-label>Organization</mat-label>
              <input matInput class="form-control" placeholder="Honeywell" readonly name="userDetails.orgName"
                [(ngModel)]="userDetails.orgName">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Description</mat-label>
              <input matInput class="form-control" placeholder="9787876767" readonly name="userDetails.description"
                [(ngModel)]="userDetails.description">
            </mat-form-field>


            <mat-form-field>
              <mat-label>GSTIN</mat-label>
              <input matInput class="form-control" readonly [(ngModel)]="userDetails.gstin" name="userDetails.gstin">

            </mat-form-field>


            <button class="login-button" mat-button type="submit" >Save Changes
              <mat-icon *ngIf="btnUserEditLoader">
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
              </mat-icon>
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
