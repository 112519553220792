import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/pages/services/device.service';
import { UserService } from 'src/app/pages/services/user.service';
import { transpileModule } from 'typescript';
import { v4 as uuidv4 } from 'uuid';
import AES256 from 'aes-everywhere';
import { GlobalService } from 'src/app/pages/services/global.service';

@Component({
  selector: 'setup-device',
  templateUrl: './setup-device.component.html',
  styleUrls: ['./setup-device.component.scss'],

})
export class SetupDeviceComponent implements OnInit {
  locations: any[] = [];
  selectedLocation;
  deviceName;
  wifiName='Honeywell';
  wifiPassword='12345678';
  orgId;
  QrCode: string = "empty";
  remaingTime = 60;
  deviceSetupStatus="";
  qrGenerationInProgress=false;
  deviceSetupIcon="";
  qrGenerated=false;
  countDownInterval;
  clock;

  constructor(private deviceService: DeviceService, private _userService: UserService,private _global:GlobalService) { }

  ngOnInit(): void {
    this._global.viewTitle="Device Setup";
    this.deviceService.GetAllLocations().subscribe(x => {
      this.locations = x.responseBody;
      if (this.locations.length > 0) {
        this.selectedLocation = this.locations[0]
      } else {
        alert("No location in this account. Add a location using mobile app")
      }
    }, error => {
      if (error.status != 888) {
        alert("Error occured while getting location. Refresh the page")
         }
    });

    this._userService.getUserDetails().subscribe(x => {
      this.orgId = x.orgId;

    }, error => {
      if (error.status != 888) {
        alert("Error occured while getting org detail. Refresh the page")
         }
    })
  }

  GenerateQR() {
    this.qrGenerationInProgress=true;
    let logicalDeviceId = uuidv4();
    this.deviceService.GetAuthCode("setup", logicalDeviceId).subscribe(x => {
      const encryptedWifiPass = AES256.encrypt(this.wifiPassword, 'c^d5*e8+LW-y%b%j7S^z@BC#=G^v**Ljz-wxk+Rcu%^ac=Z4RhrTGH=Ajk@9d8Mm');
      //console.log(AES256.decrypt(encryptedWifiPass, 'c^d5*e8+LW-y%b%j7S^z@BC#=G^v**Ljz-wxk+Rcu%^ac=Z4RhrTGH=Ajk@9d8Mm'));
      var data = {
        
        "a": "s",
        "t": x.responseBody,
        "dn": this.deviceName,
        "li": this.selectedLocation.id,
        "ln": this.selectedLocation.name,
        "oi": this.orgId,
        "ldi": logicalDeviceId,
        "s": this.wifiName,
        "p": encryptedWifiPass
      }
      console.log(data);
      this.QrCode = JSON.stringify(data);
      this.qrGenerationInProgress=false;
      this.qrGenerated=true;
      this.countDownInterval = window.setInterval(x => {
        this.remaingTime = this.remaingTime - 1;
      }, 1000);

      this.clock = window.setTimeout(x => {
        clearInterval(this.countDownInterval);
        this.remaingTime = 60;
        this.qrGenerated=false;
       
      }, 60000);


     
      
     
    }, error => {
      this.qrGenerationInProgress=false;
      alert("Error while getting auth code. Refresh this page")
    })
  }

  ScanningDone(){
        clearInterval(this.countDownInterval);
        clearTimeout(this.clock);
        this.remaingTime = 60;
        this.qrGenerated=false;
  }

}