<div *ngIf="deviceTwinUpdated" class="subscription-container">
    <div class="success-checkmark">
        <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
        </div>
    </div>
<div style="text-align: center;font-size: 16px;font-weight: 300;">{{displaytext}}</div>
<button *ngIf="headless" mat-flat-button style="margin-top: 30px;"class="go-back" (click)="goToApp()" >Go back to the app</button>
</div>
<div *ngIf="!deviceTwinUpdated" class="subscription-container" style="text-align: center;height:300px;">
    <mat-spinner style="position: absolute;left:calc(50% - 50px)" color="warn">
       
    </mat-spinner>
    <div style="width:250px; font-size: 16px;font-weight: 300;text-align: center;margin:160px auto;">Adding subscription details to device. Please wait..</div>
</div>

