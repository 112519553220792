import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from 'src/app/model/device';
import { MMSHttpResponseGeneric } from 'src/app/model/response';
import { DeviceService } from 'src/app/pages/services/device.service';
import { DatePipe } from '@angular/common'
import { CommonModule } from '@angular/common';
import { GlobalService } from 'src/app/pages/services/global.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import { animate, style, transition, trigger } from '@angular/animations';
import { ZohosubscriptionService } from 'src/app/pages/services/zohosubscription.service';
import { ZohoRetrieveSubscriptionResponse } from 'src/app/model/subscripition-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TooltipPosition} from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { LiveStreamService } from 'src/app/pages/services/livestream.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignalRService } from 'src/app/pages/services/signal-r.service';
import { zip } from 'rxjs';
import Swal from "sweetalert2";
import { SetupDeviceComponent } from '../setup-device/setup-device.component';
import { DeviceImageComponent } from '../device-image/device-image.component';
declare var $ :any;
declare var videojs:any;
declare var jQuery:any;

@Component({
  selector: 'app-devicelist',
  templateUrl: './devicelist.component.html',
  styleUrls: ['./devicelist.component.scss'],
  providers:[DatePipe],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.8s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class DevicelistComponent implements OnInit {

  deviceList: Device[] = [];
  pageLoader:boolean = false;
  btnSubscribeloader:boolean =false;
  nowDate:Date= new Date();
  IsExpired:boolean = false;
  device: Device;
  searchText = '';
  subscriptionDetails: any[] = [];
  data: any[] = [];
  isLoading = [];

  parentMessage = "message from parent";

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  //position = new FormControl(this.positionOptions[0]);

  constructor(
    private _deviceService:DeviceService, private _router:Router,public datepipe: DatePipe, 
    private _global:GlobalService, private _snackBar: MatSnackBar, private _zohosubscriptionService:ZohosubscriptionService,
    private _liveStream:LiveStreamService, private signalRService: SignalRService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.getdeviceList();
    setTimeout(() => {
    this._global.viewTitle="My Devices";
    });
    this.signalRService.signalRMessages.subscribe(x=>{
      
        console.log(x);
        var videoItem = this.deviceList.find(item=>item.id==x.LogicalDeviceId)
        if(x.IsError){
        videoItem.videoLoading=false;
        Swal.fire({
          title:"Cannot connect to device",
          text:x.ErrorMessage,
          icon:"error",
          showCloseButton:true,
        });
        }
        if(!x.IsError && x.HLSUrl){
          videoItem.videoLoading=false;
          videoItem.videoShow=true;
      var container = $("#"+videoItem.id);
      videoItem.player = videojs(container[0]);
      videoItem.player.src({
        src:x.HLSUrl,
        type: 'application/x-mpegURL'
      });
        }
      
    })
  }

  getdeviceList(){
    this.pageLoader = true;
    this._deviceService.retireveAllDevice()
    .subscribe((res:MMSHttpResponseGeneric<Device[]>)=>{
      this.deviceList = [];
      res.responseBody.forEach(element => {
        this.device = new Device();
        this.device.subscriptionId =  element.subscriptionId;
        this.device.subscriptionExpiryDate = new Date(this.datepipe.transform(element.subscriptionExpiryDate,'MM-dd-yyyy'));
        this.device.name = element.name;
        this.device.availableFeatures = element.availableFeatures;
        this.device.connected = element.connected;
        this.device.firmwareVersion = element.firmwareVersion;
        this.device.id = element.id;
        this.device.locationId = element.locationId;
        this.device.locationName = element.locationName;
        this.device.macAddress = element.macAddress;
        this.device.wifiName = element.wifiName;
        this.device.subscriptionStatus = element.subscriptionStatus;
        this.device.deviceType = element.deviceType;
        this.device.disabled = element.disabled;

        this.deviceList.push(this.device);
      });
        console.log("devices",this.deviceList);
        this.pageLoader = false;
    })
  }

  purchasePlan(data:Device){
    this._router.navigateByUrl('/page/plan?deviceId='+data.id+'&product='+ data.deviceType);
  }

  updateSubscription(data:Device){
     this._router.navigateByUrl('/page/plan?deviceId='+ data.id +'&subscriptionNumber='+ data.subscriptionId+'&product='+ data.deviceType);
  }

  // getSubscriptionDetails(data){
  //   this._zohosubscriptionService.getSubscriptionDetailsById(data.subscriptionId)
  //   .subscribe((res:MMSHttpResponseGeneric<ZohoRetrieveSubscriptionResponse>)=>{
  //     this._global.subscriptionData = res.responseBody;
  //     this._router.navigateByUrl('/page/plan?deviceId='+ data.id +'&subscriptionNumber='+ data.subscriptionId+'&product='+ data.deviceType);
  //     this.pageLoader = false;
  //     console.log("subscriptionDetails",this.subscriptionDetails);
  //   },error =>{
  //     this.pageLoader = false;
  //     console.log(error);
  //   });
  // }

  cancelSubscription(data){
    this.isLoading[data.id] = true;
    this._zohosubscriptionService.cancelSubscription({"subscriptionId":data.subscriptionId, "deviceId":data.id,"deviceName": data.name})
    .subscribe((res:MMSHttpResponseGeneric<string>)=>{
      let index = this.deviceList.findIndex(x => x.subscriptionId === data.subscriptionId);
        this.deviceList[index].subscriptionStatus = 'non_renewing';

      this.isLoading[data.id] = false;
      this._snackBar.open(res.responseBody, 'success!!', {
        duration: 5000,
      });
    },error =>{
      this.isLoading[data.id] = false;
      this._snackBar.open("error occured, please try again later", 'error!!', {
        duration: 5000,
      });
      console.log(error);
    });
  }

  reactivateSubscription(data){
    this.isLoading[data.id] = true;
    this._zohosubscriptionService.reactivateSubscription({"subscriptionId":data.subscriptionId, "deviceId":data.id,"deviceName": data.name})
    .subscribe((res:MMSHttpResponseGeneric<string>)=>{
     
      let index = this.deviceList.findIndex(x => x.subscriptionId === data.subscriptionId);
        this.deviceList[index].subscriptionStatus = 'live';

      this.isLoading[data.id] = false;
      this._snackBar.open(res.responseBody, 'success!!', {
        duration: 5000,
      });
    },error =>{
      this.isLoading[data.id] = false;
      this._snackBar.open("error occured, please try again later", 'error!!', {
        duration: 5000,
      });
      console.log(error);
    });
  }

  modifySubscription(data){
  
    this.isLoading[data.subscriptionId] = true;
    this._router.navigateByUrl('/page/plan?deviceId='+ data.id +'&subscriptionNumber='+ data.subscriptionId+'&product='+ data.deviceType);
  }

 

  playVideo(item)
  {
    item.videoLoading=true;
    
    this._liveStream.GetLiveStreamUrl(item.id).subscribe(x=>{
     
      item.stopSeqNumber=x.responseBody.stopSequenceNumber;
      this.signalRService.startConnection(x.responseBody.signalRNegotiateUrl,item.id);
    },err=>{
      
      item.videoLoading=false;
      this._snackBar.open("Error Occured while getting video URL");
    }); 
  }

  closePlayer(item){
    this._liveStream.StopLiveStream(item.id,item.stopSeqNumber).subscribe(x=>{
    item.player.pause();
    item.videoShow=false;
    delete item.player;
    this._snackBar.open("Live streaming stopped","",{
      duration:2000
    })
    },err=>{
      item.player.pause();
      item.videoShow=false;
      delete item.player;
      this._snackBar.open("Error while stopping live stream try again","",{
        duration:2000
      })
    })
    
    
  }

  OpenWSDtest(deviceId:string,deviceName:string){
    console.log(deviceId)
this._router.navigateByUrl(`/page/wsd-test?deviceId=${deviceId}&deviceName=${deviceName}`)
  }

  OpenWSDSummary(deviceId:string,deviceName:string){
    this._router.navigateByUrl(`/page/wsd-summary?deviceId=${deviceId}&deviceName=${deviceName}`)
  }

  OpenPeopleCount(deviceId:string){
    this._router.navigateByUrl(`/tev/people-count?deviceId=${deviceId}&accessToken=${localStorage.getItem("AUTH_TOKEN")}`)
  }

  

  GetPicture(item){
    this.isLoading[item.id]=true;
    this._deviceService.GetPicture(item.id).subscribe(x=>{
      this.isLoading[item.id]=false;
      item.picUrl=x.responseBody.imageUrl
      const dialogRef = this.dialog.open(DeviceImageComponent,{
        width:"100%",
        maxWidth: '100vw',
        data:{imgUrl:item.picUrl,deviceId:item.id}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    },err=>{
      this.isLoading[item.id]=false;
      alert("Device offline or error in device")
    })

  }

}


