<img class="logo" src="../../../../assets/logo/IMPACT-by-honeywell.png">

<div class="centered">
    
    <mat-card class="shadow login-box">
        
            <div class="tev-title">Login</div>
       
        <div class="login-container">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [errorStateMatcher]="matcher" autocomplete="off" placeholder="enter your email" formControlName="email">
                    
                        <mat-icon matSuffix>email</mat-icon>
                    
                    <mat-error *ngIf="loginForm.controls.email.hasError('email') && !loginForm.controls.email.hasError('required')">
                        Please enter a valid email address
                      </mat-error>
                      <mat-error *ngIf="loginForm.controls.email.hasError('required')">
                        Email is required
                      </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="enter your password"
                        formControlName="password">
                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                        Password is required
                      </mat-error>
                </mat-form-field><br>
                <button class="login-button" type="submit" mat-flat-button color="warn">
                    <span>LOGIN<mat-spinner *ngIf="loading" style="margin-left:8px;display:inline-block;position:absolute;top:10px;" class="custom" diameter="18">
                    </mat-spinner></span>
                </button>
            </form>
        </div>
        <br>
        <div class="link-container">
           <a routerLink="/forgot-password" href="#">Forgot password</a>
        </div>
    </mat-card>
    <!-- <div class="sign-up">Do not have an account? <a  routerLink="/register" href="#">Sign up</a></div> -->
    <br>
    <div style="z-index:1000;">
        <a href="https://www.honeywell.com/us/en/privacy-statement" target="_self">Privacy Statement</a>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="https://www.honeywell.com/us/en/cookie-notice" target="_self">Cookie Notice</a>
        <!-- <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> --><br>
        <a href="https://honeywellhub.secure.force.com/PrivacyInformationRequestForm?lang=en" target="_blank">Do Not Sell My Personal Information</a>
</div>
</div>
<div style="z-index:-1;position: absolute;">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180">
    <path fill="#f1623b" fill-opacity="1" d="M0,0L48,21.3C96,43,192,85,288,106.7C384,128,480,128,576,149.3C672,171,768,213,864,192C960,171,1056,85,1152,69.3C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg>
  <svg class="top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 260"><path fill="#f1623b" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,106.7C672,149,768,235,864,272C960,309,1056,299,1152,282.7C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
  </div>