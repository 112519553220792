<div class="grid-container">
    <mat-spinner style="width: 100px;
    height: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);" color="warn" *ngIf="pageLoader"></mat-spinner>
    <div class="row">
        <div style="margin-bottom: 30px;" class="col-lg-4 col-md-6" *ngFor="let item of plans; let i = index">
            <mat-card class="shadow border plan-card">
                <mat-card-content>
                    <div style="margin-bottom: 10px;" class="tev-title">{{item.name}}</div>
                    <div class="plan-description">{{item.description}}</div>
                    <div>Base Price: &#8377; {{item.recurring_price}}/{{item.interval  | singularFilter}} </div>
                </mat-card-content>
                <div class="addons">{{subheaderTextForAddOns}}</div>

                <div *ngIf="product =='WSD'">
                    <div *ngFor="let addon of item.addons" style="text-align: center;margin-top: 10px;">
                        <mat-icon style="color: #666;vertical-align: bottom;" inline="true" *ngIf="addon.name == 'Mobile Notifications'">notifications</mat-icon>
                        <mat-icon style="color: #666;vertical-align: bottom;" inline="true" *ngIf="addon.name == 'Call on Mobile'">call</mat-icon>
                        {{addon.name}}
                    </div>
                </div>
                  
                <mat-selection-list #addon [(ngModel)]="selectedAddOns"  (selectionChange)="onSelectionChange($event, i)" *ngIf="product !='WSD'">
                    <mat-list-option class="border-top" style="height:100px" *ngFor="let addon of item.addons, let j = index"
                    [disabled]="addon.isDisabled" [value]="addon" [selected]="addon.selected"  name="{{'product' + i}}">
                        <div class="addons-name">
                            {{addon.name}}
                        </div>
                        <div style="font-size: 12px;opacity: 0.8;">
                           {{addon.description}}
                        </div>
                        <div class="addons-price">
                            <span style="text-decoration: line-through;margin-right: 5px;">&#8377; {{addon.price*item.interval + 30}}</span>
                         &#8377; {{addon.price * item.interval}}/{{item.interval | singularFilter}}
                        </div>
                    </mat-list-option>
                </mat-selection-list>
              
                <div class="button-cont">
                    <button *ngIf="isNoFeatureToSelect" class="purchase-button"  mat-flat-button (click)="goToApp(i)" >
                        Go back to the app
                    </button>
                    <button *ngIf="!isNoFeatureToSelect" class="purchase-button" mat-flat-button (click)="onSubscribe(i);"> Proceed
                        <mat-icon *ngIf="isLoading[i]">
                            <mat-spinner [value]="indeterminate" color="primary" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
</div>