import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, retry, switchMap, take, tap } from 'rxjs/operators';
import { AuthToken } from '../model/authtoken';
import { MMSHttpResponseGeneric } from '../model/response';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../pages/services/global.service';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(public _authService: AuthService, private route: ActivatedRoute, public _global: GlobalService,private _router:Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add auth header with jwt if user is logged in and request is to the api url 

        if (request.url.indexOf('/api/auth/refresh') === -1 && this._authService.getJwtToken()) {
            request = this.addToken(request, this._authService.getJwtToken());
        }
        else {
            request = this.addToken(request, this._global.jwtToken);
        }

        //return next.handle(request);

        return next.handle(request).pipe(tap(resp => {
            if (resp instanceof HttpErrorResponse) {
                // do stuff with response if you want
                if (resp.status === 401) {
                    return this.handle401Error(request, next);
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    return this.handle401Error(request, next);
                }
                else if (err.status === 888) {
                    return this.handle888Error(request, next,err.error.errorMessage);
                }
                else {
                    return throwError(err.message);
                }
            }
        }));

        // return next.handle(request).pipe(retry(1), catchError(error => {
        //     if (error instanceof HttpErrorResponse && error.status === 401) {
        //         
        //         return this.handle401Error(request, next);
        //     } else {
        //         
        //         return throwError(error);
        //     }
        // }));

    }



    private addToken(request: HttpRequest<any>, token: string) {

        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
                'apv': `w-v1.0.0.0`
            }
        });
    }

    private handle888Error(request: HttpRequest<any>, next: HttpHandler,errorResponseMessage : string) {

        localStorage.removeItem(environment.JWT_TOKEN);
        localStorage.removeItem(environment.REFRESH_TOKEN);
        localStorage.removeItem(environment.CLIENT_ID);
        alert(errorResponseMessage);
        this._router.navigateByUrl('/login');
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        localStorage.removeItem(environment.JWT_TOKEN);
        localStorage.removeItem(environment.REFRESH_TOKEN);
        localStorage.removeItem(environment.CLIENT_ID);
        this._router.navigateByUrl('/login');
        
        // if (!this.isRefreshing) {

        //     this.isRefreshing = true;
        //     this.refreshTokenSubject.next(null);

        //     return this._authService.refreshToken().pipe(
        //         switchMap((data: MMSHttpResponseGeneric<AuthToken>) => {
        //             this.isRefreshing = false;
        //             this.refreshTokenSubject.next(data.responseBody.accessToken);
        //             return next.handle(this.addToken(request, data.responseBody.accessToken));
        //         }));
        // }
        // else {
        //     return this.refreshTokenSubject.pipe(
        //         filter(data => data! = null),
        //         take(1),
        //         switchMap((res: MMSHttpResponseGeneric<AuthToken>) => {
        //             return next.handle(this.addToken(request, res.responseBody.accessToken))
        //         })
        //     )
        // }
    }
}